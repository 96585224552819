import arrify from 'arrify'

import sortByText from '@/utils/sortByText'

import DEFAULT_FILTER_VALUE from '@/constants/DEFAULT_FILTER_VALUE'
import SEARCH_PROP_NAME from '@/constants/SEARCH_PROP_NAME'
import {GENDER_FEMALE} from '@/constants/genderList'
import {
  magicItemSourceIdList,
  magicItemSpellIdList,
} from '@/constants/magicItemList'
import {
  FILTER_TYPE_INPUT,
  FILTER_TYPE_SELECT,
} from '@/constants/filterTypes'
import {sourceCollection} from '@/constants/sourceList'
import {spellCollection} from '@/constants/spellList'

import {
  magicItemRarityCollection,
  MGC_RARITY_COMMON,
  MGC_RARITY_UNCOMMON,
  MGC_RARITY_RARE,
  MGC_RARITY_VERY_RARE,
  MGC_RARITY_LEGENDARY,
  MGC_RARITY_ARTIFACT,
} from '@/constants/magicItemRarityList'
import {
  magicItemTypeCollection,
  MGC_TYPE_ARMOR,
  MGC_TYPE_POTION,
  MGC_TYPE_RING,
  MGC_TYPE_ROD,
  MGC_TYPE_SCROLL,
  MGC_TYPE_STAFF,
  MGC_TYPE_WAND,
  MGC_TYPE_WEAPON,
  MGC_TYPE_WONDROUS_ITEM,
} from '@/constants/magicItemTypeList'

export default [
  {
    label: 'Редкость',
    type: FILTER_TYPE_SELECT,
    propName: 'rarity',
    value: DEFAULT_FILTER_VALUE,
    filterHandler: value => item => {
      if (value) {
        if (item.rarity) {
          const propToCheck = arrify(item.rarity).map(
            rarityItem => typeof rarityItem === 'string'
              ? rarityItem
              : rarityItem.value
          );

          return arrify(value).some(
            valueItem => valueItem === DEFAULT_FILTER_VALUE || propToCheck.includes(valueItem)
          )
        }

        return false
      }

      return true
    },
    list: [
      {
        value: DEFAULT_FILTER_VALUE,
        text: 'Любая'
      },
      ...[
        MGC_RARITY_COMMON,
        MGC_RARITY_UNCOMMON,
        MGC_RARITY_RARE,
        MGC_RARITY_VERY_RARE,
        MGC_RARITY_LEGENDARY,
        MGC_RARITY_ARTIFACT,
      ]
        .map(
          rarityId  => ({
            value: rarityId,
            text: magicItemRarityCollection[rarityId].nameByGender[GENDER_FEMALE],
          })
        )
    ],
  },
  {
    label: 'Настройка',
    title: `Нужна ли настройка?`,
    type: FILTER_TYPE_SELECT,
    propName: 'attunement',
    value: DEFAULT_FILTER_VALUE,
    list: [
      {
        value: DEFAULT_FILTER_VALUE,
        text: 'Неважно',
      },
      {
        value: true,
        text: 'Да',
      },
      {
        value: false,
        text: 'Нет',
      },
    ],
  },
  {
    label: 'Тип',
    type: FILTER_TYPE_SELECT,
    propName: 'type',
    value: DEFAULT_FILTER_VALUE,
    list: [
      {
        value: DEFAULT_FILTER_VALUE,
        text: 'Любой'
      },
      ...[
        MGC_TYPE_ARMOR,
        MGC_TYPE_POTION,
        MGC_TYPE_RING,
        MGC_TYPE_ROD,
        MGC_TYPE_SCROLL,
        MGC_TYPE_STAFF,
        MGC_TYPE_WAND,
        MGC_TYPE_WEAPON,
        MGC_TYPE_WONDROUS_ITEM,
      ]
        .map(
          value  => {
            const { name: text, description: title } = magicItemTypeCollection[value]
            return {
              value,
              text,
              title,
            }
          }
        )
        .sort(sortByText)
    ],
  },
  {
    label: 'Источник',
    type: FILTER_TYPE_SELECT,
    propName: 'sourceIdList',
    value: DEFAULT_FILTER_VALUE,
    list: [
      {
        value: DEFAULT_FILTER_VALUE,
        text: 'Неважно',
      },
      ...magicItemSourceIdList
        .map(
          id => ({
            value: id,
            text: sourceCollection[id].name,
          }),
        )
        .sort(sortByText),
    ],
  },
  {
    label: 'Заклинания',
    title: 'Какие предмет позволяет сотворять заклинания или дублировать эффекты заклинаний?',
    type: FILTER_TYPE_SELECT,
    propName: 'spellIdList',
    value: DEFAULT_FILTER_VALUE,
    list: [
      {
        value: DEFAULT_FILTER_VALUE,
        text: 'Неважно',
      },
      ...magicItemSpellIdList
        .map(
          id => ({
            value: id,
            text: spellCollection[id].name,
          }),
        )
        .sort(sortByText),
    ],
  },
  {
    label: 'Проклятие',
    title: `Проклят ли предмет?`,
    type: FILTER_TYPE_SELECT,
    propName: 'hasCurse',
    value: DEFAULT_FILTER_VALUE,
    list: [
      {
        value: DEFAULT_FILTER_VALUE,
        text: 'Неважно',
      },
      {
        value: true,
        text: 'Да',
      },
      {
        value: false,
        text: 'Нет',
      },
    ],
  },
  {
    label: 'Симбионт',
    title: `Симбионт ли это?`,
    type: FILTER_TYPE_SELECT,
    propName: 'isSymbiotic',
    value: DEFAULT_FILTER_VALUE,
    list: [
      {
        value: DEFAULT_FILTER_VALUE,
        text: 'Неважно',
      },
      {
        value: true,
        text: 'Да',
      },
      {
        value: false,
        text: 'Нет',
      },
    ],
  },
  {
    label: 'Разум',
    title: `Разумен ли предмет?`,
    type: FILTER_TYPE_SELECT,
    propName: 'isSentient',
    value: DEFAULT_FILTER_VALUE,
    list: [
      {
        value: DEFAULT_FILTER_VALUE,
        text: 'Неважно',
      },
      {
        value: true,
        text: 'Да',
      },
      {
        value: false,
        text: 'Нет',
      },
    ],
  },
  {
    label: 'Расходник',
    title: `Расходуемый ли это предмет?`,
    type: FILTER_TYPE_SELECT,
    propName: 'isConsumable',
    value: DEFAULT_FILTER_VALUE,
    list: [
      {
        value: DEFAULT_FILTER_VALUE,
        text: 'Неважно',
      },
      {
        value: true,
        text: 'Да',
      },
      {
        value: false,
        text: 'Нет',
      },
    ],
  },
  {
    label: 'Дочерний',
    title: `Дочерние предметы — это конкретные магические предметы, созданные по определённому шаблону.`,
    type: FILTER_TYPE_SELECT,
    propName: 'isChild',
    value: false,
    list: [
      {
        value: DEFAULT_FILTER_VALUE,
        text: 'Неважно',
      },
      {
        value: true,
        text: 'Да',
      },
      {
        value: false,
        text: 'Нет',
      },
    ],
  },
  {
    label: 'Шаблон',
    title: `Шаблон — это не готовый магический предмет, а только набор правил для его создания или вариантов на выбор.`,
    type: FILTER_TYPE_SELECT,
    propName: 'isTemplate',
    value: DEFAULT_FILTER_VALUE,
    list: [
      {
        value: DEFAULT_FILTER_VALUE,
        text: 'Неважно',
      },
      {
        value: true,
        text: 'Да',
      },
      {
        value: false,
        text: 'Нет',
      },
    ],
  },
  {
    label: 'Введите название предмета или текст его описания',
    type: FILTER_TYPE_INPUT,
    propName: SEARCH_PROP_NAME,
    value: DEFAULT_FILTER_VALUE,
  },
]
