import React from 'react'
import arrify from 'arrify'

import GearLink from '@/components/GearLink'

export default ({ gearIdList }) => {
  if (gearIdList) {
    return (
      <span>
        {
          arrify(gearIdList).map(
            gearId => (
              <GearLink
                gearId={gearId}
                key={gearId}
              />
            ),
          )
        }
      </span>
    )
  }

  return null
}
