import React from 'react'

import magicItemList from '@/constants/magicItemList'
import Catalog from '@/components/Catalog'

import generateMagicItemPageUrlById from "@/utils/generateMagicItemPageUrlById"

import filterListOriginal from './constants/filterList'

const MagicItemCatalogContainer = () => (
  <Catalog
    pageUrlGenerator={generateMagicItemPageUrlById}
    pageTitle='Каталог магических предметов'
    itemNameList={magicItemList}
    itemList={magicItemList}
    filterListOriginal={filterListOriginal}
  />
)

export default MagicItemCatalogContainer
