const {
  MAGIC_ITEM_INFERNAL_TACK,
} = require('./../../magicItemIdList')

const {
  Narzugon_MTOF_data,
} = require('./../commonImageCollection')

module.exports = {
  [MAGIC_ITEM_INFERNAL_TACK]: Narzugon_MTOF_data,
}
