import React from 'react'

import GearCategoryList from '@/components/GearCategoryList'
import GearLinkList from '@/components/GearLinkList'
import Markdown from '@/components/Markdown'

import generateTextLinks from '@/utils/generateTextLinks'

import './MagicItemTypeAdditionalTextStyles.less'

const MagicItemTypeAdditionalText = (
  {
    gearCategory,
    gearType,
    gearTypeExclude,
    typeComment,
  }
) => gearCategory || gearTypeExclude || gearType || typeComment
  ? (
    <span className='MagicItemTypeAdditionalText'>
      <span> (</span>
      <GearCategoryList catIdList={gearCategory}/>
      <GearLinkList gearIdList={gearType} />
      {
        gearTypeExclude
          ? (
            <span>
              <span>, но не </span>
              <GearLinkList gearIdList={gearTypeExclude}/>
            </span>
          )
          : null
      }{
        typeComment && (gearCategory || gearType || gearTypeExclude)
          ? ','
          : null
      }{
        typeComment
          ? (
            <Markdown className='MagicItemTypeAdditionalText_typeComment'>
              {generateTextLinks(typeComment)}
            </Markdown>
          )
          : null
      })
    </span>
  )
  : null

export default MagicItemTypeAdditionalText
