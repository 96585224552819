const {
  SOURCE_PotA,
} = require('./../../sourceList')
const {
  MAGIC_ITEM_DROWN,
  MAGIC_ITEM_IRONFANG,
  MAGIC_ITEM_TINDERSTRIKE,
  MAGIC_ITEM_WEIRD_TANK,
  MAGIC_ITEM_WINDVANE,
} = require('./../../magicItemIdList')

const {
  Marlos_urnrayle_with_ironfang_PotA_data,
} = require('./../commonImageCollection')

const Windvane_PotA_img = require('./../../../images/magicItems/pota/windvane.png')
const Tinderstrike_PotA_img = require('./../../../images/magicItems/pota/tinderstrike.png')
const Ironfang_PotA_img = require('./../../../images/magicItems/pota/ironfang.png')
const Drown_PotA_img = require('./../../../images/magicItems/pota/drown.png')
const Weird_tank_PotA_img = require('./../../../images/magicItems/pota/weird_tank.png')

module.exports = {
  [MAGIC_ITEM_DROWN]: {
    src: Drown_PotA_img,
    source: {
      id: SOURCE_PotA,
    },
  },
  [MAGIC_ITEM_IRONFANG]: [
    {
      src: Ironfang_PotA_img,
      source: {
        id: SOURCE_PotA,
      },
    },
    Marlos_urnrayle_with_ironfang_PotA_data,
  ],
  [MAGIC_ITEM_TINDERSTRIKE]: {
    src: Tinderstrike_PotA_img,
    source: {
      id: SOURCE_PotA,
    },
  },
  [MAGIC_ITEM_WEIRD_TANK]: {
    src: Weird_tank_PotA_img,
    source: {
      id: SOURCE_PotA,
    },
  },
  [MAGIC_ITEM_WINDVANE]: {
    src: Windvane_PotA_img,
    source: {
      id: SOURCE_PotA,
    },
  },
}
