import React from 'react'

import './MagicItemTypeStyles.less'

const MagicItemTypeComponent = (
  {
    text,
    title,
  },
) => (
  <span
    className='MagicItemType'
    title={title}
  >
    {text}
  </span>
)

export default MagicItemTypeComponent
