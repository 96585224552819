import React from 'react'

import { magicItemTypeCollection } from '@/constants/magicItemTypeList'

import MagicItemTypeComponent from './MagicItemTypeComponent'

const MagicItemTypeContainer = ({type}) => {
  if (type) {
    const {name, description} = magicItemTypeCollection[type]
    const title = `${name}\n${description}`

    return (
      <MagicItemTypeComponent
        text={name}
        title={title}
      />
    )
  }

  return null
}

export default MagicItemTypeContainer
