const {
  MAGIC_ITEM_BLACKSTAFF,
  MAGIC_ITEM_PAPER_BIRD,
} = require('./../../magicItemIdList')

const {Vajra_Safahr_WDH_data} = require('./../commonImageCollection')

const Paper_bird_WDH_img = require('./../../../images/magicItems/wdh/paper_bird.png')

module.exports = {
  [MAGIC_ITEM_PAPER_BIRD]: Paper_bird_WDH_img,
  [MAGIC_ITEM_BLACKSTAFF]: Vajra_Safahr_WDH_data,
}
