import React from 'react'
import arrify from 'arrify'

import GearCategory from '@/components/GearCategory'

export default ({ catIdList }) => {
  if (catIdList) {
    return (
      <span>
        {
          arrify(catIdList).map(
            catId => (
              <GearCategory
                catId={catId}
                key={catId}
              />
            ),
          )
        }
      </span>
    )
  }

  return null
}
