module.exports = {
  ...require('./magicItemImageCollection_BGDIA'),
  ...require('./magicItemImageCollection_DMG'),
  ...require('./magicItemImageCollection_ERFTLW'),
  ...require('./magicItemImageCollection_HOTDQ'),
  ...require('./magicItemImageCollection_MOOT'),
  ...require('./magicItemImageCollection_MTOF'),
  ...require('./magicItemImageCollection_PotA'),
  ...require('./magicItemImageCollection_TCOE'),
  ...require('./magicItemImageCollection_WDH'),
}
