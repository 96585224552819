import React from 'react'
import arrify from 'arrify'

import { magicItemCollection } from '@/constants/magicItemList'

import generateNameStr from '@/utils/generateNameStr'
import getTextFromDescription from '@/utils/getTextFromDescription'

import MagicItemComponent from './MagicItemComponent'

const MagicItemContainer = (
  {
    id,
    magicItem = magicItemCollection[id],
  }
) => {
  if (magicItem) {
    const {header, subHeader} = generateNameStr(magicItem)
    const {description, curse, symbiotic} = magicItem

    const curseDescr = curse ? {
      header: 'Проклятье',
      text: curse,
    } : null
    const symbioticDescr = symbiotic ? {
      header: 'Симбиотическая природа',
      text: symbiotic,
    } : null


    const descriptionAdditional = arrify(description).concat(symbioticDescr, curseDescr).map(
      (item) => ({
        upHeader: header,
        ...item,
      })
    )
    const descriptionToShow = descriptionAdditional.shift()
    const descriptionText = getTextFromDescription(descriptionToShow)

    return (
      <MagicItemComponent
        header={header}
        subHeader={subHeader}
        magicItem={magicItem}
        text={descriptionText}
        descriptionAdditional={descriptionAdditional}
      />
    )
  }

  return null
}

export default MagicItemContainer
