const {
  SOURCE_BGDIA,
} = require('./../../sourceList')
const {
  MAGIC_ITEM_MATALOTOK,
} = require('./../../magicItemIdList')

const Matalotok_BGDIA_img = require('./../../../images/magicItems/bgdia/matalotok.jpeg')

const magicItemsImageList_BGDIA = {
  [MAGIC_ITEM_MATALOTOK]: {
    src: Matalotok_BGDIA_img,
    text: 'Маталоток, Отец Мороз',
    source: {
      id: SOURCE_BGDIA,
    },
  },
}

module.exports = magicItemsImageList_BGDIA
