const {
  SOURCE_ERFTLW,
} = require('./../../sourceList')
const {
  MAGIC_ITEM_ARM_BLADE,
  MAGIC_ITEM_DYRRN_S_TENTACLE_WHIP,
  MAGIC_ITEM_PROSTHETIC_LIMB,
} = require('./../../magicItemIdList')
const {
  Dwarf_with_Tentacle_Whip_ERFTLW_data,
  Armblade_Warforged_WGtE_data,
} = require('./../commonImageCollection')

const Prosthetic_limb_ERFTLW_img = require('./../../../images/magicItems/erftlw/prosthetic_limb.jpg')

const magicItemsImageList_ERFTLW = {
  [MAGIC_ITEM_PROSTHETIC_LIMB]: {
    src: Prosthetic_limb_ERFTLW_img,
    source: {
      id: SOURCE_ERFTLW,
    },
  },
  [MAGIC_ITEM_DYRRN_S_TENTACLE_WHIP]: Dwarf_with_Tentacle_Whip_ERFTLW_data,
  [MAGIC_ITEM_ARM_BLADE]: Armblade_Warforged_WGtE_data,
}

module.exports = magicItemsImageList_ERFTLW
