const listToCollectionById = require('./../../../utils/listToCollectionById')
const {
  SOURCE_HOTDQ,
} = require('./../../sourceList')
const {
  MAGIC_ITEM_WAND_OF_WINTER,
} = require('./../../magicItemIdList')

const Wand_of_Winter_HOTDQ_img = require('./../../../images/magicItems/hotdq/wand_of_winter.png')

const magicItemsImageList_HOTDQ = [
  {
    id: MAGIC_ITEM_WAND_OF_WINTER,
    src: Wand_of_Winter_HOTDQ_img,
  },
]
  .map(
    gear => ({
      ...gear,
      source: {
        id: SOURCE_HOTDQ,
      },
    })
  )

module.exports = listToCollectionById(magicItemsImageList_HOTDQ)
