const listToCollectionById = require('./../../../utils/listToCollectionById')
const {
  SOURCE_DMG,
  SOURCE_FTD,
  SOURCE_MTG_AFR,
  SOURCE_MTG_CLB,
  SOURCE_MTG_M20,
} = require('./../../sourceList')
const {
  MAGIC_ITEM_ALCHEMY_JUG,
  MAGIC_ITEM_AMULET_OF_HEALTH,
  MAGIC_ITEM_AMULET_OF_PROOF_AGAINST_DETECTION_AND_LOCATION,
  MAGIC_ITEM_AMULET_OF_THE_PLANES,
  MAGIC_ITEM_ANIMATED_SHIELD,
  MAGIC_ITEM_ANSTRUTH_HARP,
  MAGIC_ITEM_APPARATUS_OF_KWALISH,
  MAGIC_ITEM_ARMOR_OF_INVULNERABILITY,
  MAGIC_ITEM_ARMOR_OF_RESISTANCE,
  MAGIC_ITEM_ARROW_OF_SLAYING,
  MAGIC_ITEM_AXE_OF_THE_DWARVISH_LORDS,
  MAGIC_ITEM_BAG_OF_BEANS,
  MAGIC_ITEM_BAG_OF_DEVOURING,
  MAGIC_ITEM_BAG_OF_HOLDING,
  MAGIC_ITEM_BAG_OF_TRICKS_GRAY,
  MAGIC_ITEM_BAG_OF_TRICKS_RUST,
  MAGIC_ITEM_BAG_OF_TRICKS_TAN,
  MAGIC_ITEM_BELT_OF_DWARVENKIND,
  MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_CLOUD,
  MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_FIRE,
  MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_FROST,
  MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_HILL,
  MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_STONE,
  MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_STORM,
  MAGIC_ITEM_BERSERKER_AXE,
  MAGIC_ITEM_BOOK_OF_EXALTED_DEEDS,
  MAGIC_ITEM_BOOK_OF_VILE_DARKNESS,
  MAGIC_ITEM_BOOTS_OF_ELVENKIND,
  MAGIC_ITEM_BOOTS_OF_SPEED,
  MAGIC_ITEM_BOOTS_OF_STRIDING_AND_SPRINGING,
  MAGIC_ITEM_BOOTS_OF_THE_WINTERLANDS,
  MAGIC_ITEM_BOWL_OF_COMMANDING_WATER_ELEMENTALS,
  MAGIC_ITEM_BRACERS_OF_ARCHERY,
  MAGIC_ITEM_BRACERS_OF_DEFENSE,
  MAGIC_ITEM_BRAZIER_OF_COMMANDING_FIRE_ELEMENTALS,
  MAGIC_ITEM_BROOCH_OF_SHIELDING,
  MAGIC_ITEM_BROOM_OF_FLYING,
  MAGIC_ITEM_CANAITH_MANDOLIN,
  MAGIC_ITEM_CANDLE_OF_INVOCATION,
  MAGIC_ITEM_CAPE_OF_THE_MOUNTEBANK,
  MAGIC_ITEM_CENSER_OF_CONTROLLING_AIR_ELEMENTALS,
  MAGIC_ITEM_CHIME_OF_OPENING,
  MAGIC_ITEM_CIRCLET_OF_BLASTING,
  MAGIC_ITEM_CLI_LYRE,
  MAGIC_ITEM_CLOAK_OF_DISPLACEMENT,
  MAGIC_ITEM_CLOAK_OF_ELVENKIND,
  MAGIC_ITEM_CLOAK_OF_PROTECTION,
  MAGIC_ITEM_CLOAK_OF_THE_MANTA_RAY,
  MAGIC_ITEM_CUBE_OF_FORCE,
  MAGIC_ITEM_CUBIC_GATE,
  MAGIC_ITEM_DAERNS_INSTANT_FORTRESS,
  MAGIC_ITEM_DAGGER_OF_VENOM,
  MAGIC_ITEM_DANCING_SWORD,
  MAGIC_ITEM_DECANTER_OF_ENDLESS_WATER,
  MAGIC_ITEM_DECK_OF_MANY_THINGS,
  MAGIC_ITEM_DEFENDER,
  MAGIC_ITEM_DEMON_ARMOR,
  MAGIC_ITEM_DIMENSIONAL_SHACKLES,
  MAGIC_ITEM_DOSS_LUTE,
  MAGIC_ITEM_DRAGON_SCALE_MAIL_BLACK,
  MAGIC_ITEM_DRAGON_SCALE_MAIL_BLUE,
  MAGIC_ITEM_DRAGON_SCALE_MAIL_BRASS,
  MAGIC_ITEM_DRAGON_SCALE_MAIL_BRONZE,
  MAGIC_ITEM_DRAGON_SCALE_MAIL_COPPER,
  MAGIC_ITEM_DRAGON_SCALE_MAIL_GOLD,
  MAGIC_ITEM_DRAGON_SCALE_MAIL_GREEN,
  MAGIC_ITEM_DRAGON_SCALE_MAIL_RED,
  MAGIC_ITEM_DRAGON_SCALE_MAIL_SILVER,
  MAGIC_ITEM_DRAGON_SCALE_MAIL_WHITE,
  MAGIC_ITEM_DRAGON_SLAYER,
  MAGIC_ITEM_DRIFTGLOBE,
  MAGIC_ITEM_DUST_OF_DISAPPEARANCE,
  MAGIC_ITEM_DUST_OF_SNEEZING_AND_CHOKING,
  MAGIC_ITEM_DWARVEN_PLATE,
  MAGIC_ITEM_DWARVEN_THROWER,
  MAGIC_ITEM_EFREETI_BOTTLE,
  MAGIC_ITEM_EFREETI_CHAIN,
  MAGIC_ITEM_ELEMENTAL_GEM_AIR,
  MAGIC_ITEM_ELEMENTAL_GEM_EARTH,
  MAGIC_ITEM_ELEMENTAL_GEM_FIRE,
  MAGIC_ITEM_ELEMENTAL_GEM_WATER,
  MAGIC_ITEM_ELVEN_CHAIN,
  MAGIC_ITEM_EVERSMOKING_BOTTLE,
  MAGIC_ITEM_EYE_OF_VECNA,
  MAGIC_ITEM_EYES_OF_CHARMING,
  MAGIC_ITEM_EYES_OF_THE_EAGLE,
  MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_BRONZE_GRIFFON,
  MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_EBONY_FLY,
  MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_GOLDEN_LIONS,
  MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_IVORY_GOATS,
  MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_MARBLE_ELEPHANT,
  MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_OBSIDIAN_STEED,
  MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_ONYX_DOG,
  MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_SERPENTINE_OWL,
  MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_SILVER_RAVEN,
  MAGIC_ITEM_FLAME_TONGUE,
  MAGIC_ITEM_FOCHLUCAN_BANDORE,
  MAGIC_ITEM_FOLDING_BOAT,
  MAGIC_ITEM_FROST_BRAND,
  MAGIC_ITEM_GAUNTLETS_OF_OGRE_POWER,
  MAGIC_ITEM_GEM_OF_BRIGHTNESS,
  MAGIC_ITEM_GEM_OF_SEEING,
  MAGIC_ITEM_GIANT_SLAYER,
  MAGIC_ITEM_GLOVES_OF_MISSILE_SNARING,
  MAGIC_ITEM_GOGGLES_OF_NIGHT,
  MAGIC_ITEM_HAMMER_OF_THUNDERBOLTS,
  MAGIC_ITEM_HAND_OF_VECNA,
  MAGIC_ITEM_HEADBAND_OF_INTELLECT,
  MAGIC_ITEM_HELM_OF_BRILLIANCE,
  MAGIC_ITEM_HELM_OF_COMPREHENDING_LANGUAGES,
  MAGIC_ITEM_HELM_OF_TELEPATHY,
  MAGIC_ITEM_HEWARD_S_HANDY_HAVERSACK,
  MAGIC_ITEM_HOLY_AVENGER,
  MAGIC_ITEM_HORN_OF_BLASTING,
  MAGIC_ITEM_HORN_OF_VALHALLA_BRASS,
  MAGIC_ITEM_HORN_OF_VALHALLA_BRONZE,
  MAGIC_ITEM_HORN_OF_VALHALLA_IRON,
  MAGIC_ITEM_HORN_OF_VALHALLA_SILVER,
  MAGIC_ITEM_IMMOVABLE_ROD,
  MAGIC_ITEM_IRON_BANDS_OF_BILARRO,
  MAGIC_ITEM_IRON_FLASK,
  MAGIC_ITEM_JAVELIN_OF_LIGHTNING,
  MAGIC_ITEM_KEOGHTOM_S_OINTMENT,
  MAGIC_ITEM_LANTERN_OF_REVEALING,
  MAGIC_ITEM_LUCK_BLADE,
  MAGIC_ITEM_MAC_FUIRMIDH_CITTERN,
  MAGIC_ITEM_MACE_OF_DISRUPTION,
  MAGIC_ITEM_MACE_OF_SMITING,
  MAGIC_ITEM_MACE_OF_TERROR,
  MAGIC_ITEM_MANTLE_OF_SPELL_RESISTANCE,
  MAGIC_ITEM_MANUAL_OF_BODILY_HEALTH,
  MAGIC_ITEM_MANUAL_OF_GOLEMS_CLAY,
  MAGIC_ITEM_MANUAL_OF_GOLEMS_FLESH,
  MAGIC_ITEM_MANUAL_OF_GOLEMS_IRON,
  MAGIC_ITEM_MANUAL_OF_GOLEMS_STONE,
  MAGIC_ITEM_MANUAL_OF_QUICKNESS_OF_ACTION,
  MAGIC_ITEM_MEDALLION_OF_THOUGHTS,
  MAGIC_ITEM_MIRROR_OF_LIFE_TRAPPING,
  MAGIC_ITEM_MYSTERY_KEY,
  MAGIC_ITEM_NECKLACE_OF_ADAPTATION,
  MAGIC_ITEM_NECKLACE_OF_FIREBALLS,
  MAGIC_ITEM_NECKLACE_OF_PRAYER_BEADS,
  MAGIC_ITEM_NINE_LIVES_STEALER,
  MAGIC_ITEM_NOLZURS_MARVELOUS_PIGMENTS,
  MAGIC_ITEM_OATHBOW,
  MAGIC_ITEM_OLLAMH_HARP,
  MAGIC_ITEM_ORB_OF_DRAGONKIND,
  MAGIC_ITEM_PERIAPT_OF_HEALTH,
  MAGIC_ITEM_PERIAPT_OF_WOUND_CLOSURE,
  MAGIC_ITEM_PIPES_OF_HAUNTING,
  MAGIC_ITEM_PIPES_OF_THE_SEWERS,
  MAGIC_ITEM_POTION_OF_HEALING,
  MAGIC_ITEM_POTION_OF_HEALING_COMMON,
  MAGIC_ITEM_POTION_OF_SUPERIOR_HEALING,
  MAGIC_ITEM_POTION_OF_SUPREME_HEALING,
  MAGIC_ITEM_POTION_OF_GREATER_HEALING,
  MAGIC_ITEM_QUAALS_FEATHER_TOKEN_ANCHOR,
  MAGIC_ITEM_QUAALS_FEATHER_TOKEN_BIRD,
  MAGIC_ITEM_QUAALS_FEATHER_TOKEN_FAN,
  MAGIC_ITEM_QUAALS_FEATHER_TOKEN_SWAN_BOAT,
  MAGIC_ITEM_QUAALS_FEATHER_TOKEN_TREE,
  MAGIC_ITEM_QUAALS_FEATHER_TOKEN_WHIP,
  MAGIC_ITEM_QUIVER_OF_EHLONNA,
  MAGIC_ITEM_RING_OF_ANIMAL_INFLUENCE,
  MAGIC_ITEM_RING_OF_DJINNI_SUMMONING,
  MAGIC_ITEM_RING_OF_ELEMENTAL_COMMAND_AIR,
  MAGIC_ITEM_RING_OF_EVASION,
  MAGIC_ITEM_RING_OF_FEATHER_FALLING,
  MAGIC_ITEM_RING_OF_FREE_ACTION,
  MAGIC_ITEM_RING_OF_INVISIBILITY,
  MAGIC_ITEM_RING_OF_MIND_SHIELDING,
  MAGIC_ITEM_RING_OF_PROTECTION,
  MAGIC_ITEM_RING_OF_REGENERATION,
  MAGIC_ITEM_RING_OF_RESISTANCE_ELECTRICITY,
  MAGIC_ITEM_RING_OF_SHOOTING_STARS,
  MAGIC_ITEM_RING_OF_SPELL_STORING,
  MAGIC_ITEM_RING_OF_SPELL_TURNING,
  MAGIC_ITEM_RING_OF_TELEKINESIS,
  MAGIC_ITEM_RING_OF_THE_RAM,
  MAGIC_ITEM_RING_OF_THREE_WISHES,
  MAGIC_ITEM_RING_OF_WATER_WALKING,
  MAGIC_ITEM_RING_OF_X_RAY_VISION,
  MAGIC_ITEM_ROBE_OF_EYES,
  MAGIC_ITEM_ROBE_OF_STARS,
  MAGIC_ITEM_ROBE_OF_THE_ARCHMAGI,
  MAGIC_ITEM_ROBE_OF_USEFUL_ITEMS,
  MAGIC_ITEM_ROD_OF_ABSORPTION,
  MAGIC_ITEM_ROD_OF_LORDLY_MIGHT,
  MAGIC_ITEM_ROD_OF_RESURRECTION,
  MAGIC_ITEM_ROD_OF_RULERSHIP,
  MAGIC_ITEM_ROPE_OF_ENTANGLEMENT,
  MAGIC_ITEM_SCARAB_OF_PROTECTION,
  MAGIC_ITEM_SENDING_STONES,
  MAGIC_ITEM_SHIELD_OF_MISSILE_ATTRACTION,
  MAGIC_ITEM_SLIPPERS_OF_SPIDER_CLIMBING,
  MAGIC_ITEM_SOVEREIGN_GLUE,
  MAGIC_ITEM_SPELLGUARD_SHIELD,
  MAGIC_ITEM_STAFF_OF_CHARMING,
  MAGIC_ITEM_STAFF_OF_FIRE,
  MAGIC_ITEM_STAFF_OF_FROST,
  MAGIC_ITEM_STAFF_OF_HEALING,
  MAGIC_ITEM_STAFF_OF_POWER,
  MAGIC_ITEM_STAFF_OF_SWARMING_INSECTS,
  MAGIC_ITEM_STAFF_OF_THE_ADDER,
  MAGIC_ITEM_STAFF_OF_THE_MAGI,
  MAGIC_ITEM_STAFF_OF_THE_PYTHON,
  MAGIC_ITEM_STAFF_OF_THE_WOODLANDS,
  MAGIC_ITEM_STAFF_OF_THUNDER_AND_LIGHTNING,
  MAGIC_ITEM_STAFF_OF_WITHERING,
  MAGIC_ITEM_STONE_OF_CONTROLLING_EARTH_ELEMENTALS,
  MAGIC_ITEM_STONE_OF_GOOD_LUCK,
  MAGIC_ITEM_SUN_BLADE,
  MAGIC_ITEM_SWORD_OF_KAS,
  MAGIC_ITEM_SWORD_OF_LIFE_STEALING,
  MAGIC_ITEM_SWORD_OF_SHARPNESS,
  MAGIC_ITEM_TALISMAN_OF_THE_SPHERE,
  MAGIC_ITEM_TALISMAN_OF_ULTIMATE_EVIL,
  MAGIC_ITEM_TOME_OF_CLEAR_THOUGHT,
  MAGIC_ITEM_TOME_OF_LEADERSHIP_AND_INFLUENCE,
  MAGIC_ITEM_TOME_OF_UNDERSTANDING,
  MAGIC_ITEM_TRIDENT_OF_FISH_COMMAND,
  MAGIC_ITEM_UNIVERSAL_SOLVENT,
  MAGIC_ITEM_VICIOUS_WEAPON,
  MAGIC_ITEM_VORPAL_SWORD,
  MAGIC_ITEM_WAND_OF_BINDING,
  MAGIC_ITEM_WAND_OF_ENEMY_DETECTION,
  MAGIC_ITEM_WAND_OF_FEAR,
  MAGIC_ITEM_WAND_OF_FIREBALLS,
  MAGIC_ITEM_WAND_OF_LIGHTNING_BOLTS,
  MAGIC_ITEM_WAND_OF_MAGIC_DETECTION,
  MAGIC_ITEM_WAND_OF_MAGIC_MISSILES,
  MAGIC_ITEM_WAND_OF_ORCUS,
  MAGIC_ITEM_WAND_OF_PARALYSIS,
  MAGIC_ITEM_WAND_OF_POLYMORPH,
  MAGIC_ITEM_WAND_OF_SECRETS,
  MAGIC_ITEM_WAND_OF_WEB,
  MAGIC_ITEM_WAND_OF_WONDER,
  MAGIC_ITEM_WEAPON_OF_WARNING,
  MAGIC_ITEM_WIND_FAN,
  MAGIC_ITEM_WINGED_BOOTS,
  MAGIC_ITEM_WINGS_OF_FLYING,
} = require('./../../magicItemIdList')

const {
  Dragonborn_Flame_Tongue_AYAGWW_data,
  Doss_lute_DMG_data,
  Vecna_MTG_AFR_data,
  Potion_of_healing_AYAGWW_data,
  Potion_of_Healing_MTG_AFR_data,
} = require('./../commonImageCollection')

const Alchemy_jug_DMG_img = require('./../../../images/magicItems/dmg/alchemy_jug.jpeg')
const Amulet_of_health_DMG_img = require('./../../../images/magicItems/dmg/amulet_of_health.jpeg')
const Amulet_of_proof_against_detection_and_location_DMG_img = require('./../../../images/magicItems/dmg/amulet_of_proof_against_detection_and_location.jpeg')
const Amulet_of_the_planes_DMG_img = require('./../../../images/magicItems/dmg/amulet_of_the_planes.jpeg')
const Animated_shield_DMG_img = require('./../../../images/magicItems/dmg/animated_shield.jpeg')
const Anstruth_harp_DMG_img = require('./../../../images/magicItems/dmg/anstruth_harp.jpg')
const Apparatus_of_kwalish_DMG_img = require('./../../../images/magicItems/dmg/apparatus_of_kwalish.jpeg')
const Armor_of_invulnerability_DMG_img = require('./../../../images/magicItems/dmg/armor_of_invulnerability.jpeg')
const Armor_of_resistance_DMG_img = require('./../../../images/magicItems/dmg/armor_of_resistance.jpeg')
const Arrow_of_slaying_DMG_img = require('./../../../images/magicItems/dmg/arrow_of_slaying.jpeg')
const Axe_of_the_dwarvish_lords_DMG_img = require('./../../../images/magicItems/dmg/axe_of_the_dwarvish_lords.jpeg')
const Bag_of_beans_DMG_img = require('./../../../images/magicItems/dmg/bag_of_beans.jpeg')
const Bag_of_devouring_DMG_img = require('./../../../images/magicItems/dmg/bag_of_devouring.jpeg')
const Bag_of_holding_DMG_img = require('./../../../images/magicItems/dmg/bag_of_holding.jpeg')
const Bag_of_tricks_DMG_img = require('./../../../images/magicItems/dmg/bag_of_tricks.jpeg')
const Belt_of_dwarvenkind_DMG_img = require('./../../../images/magicItems/dmg/belt_of_dwarvenkind.jpeg')
const Belt_of_giant_strength_DMG_img = require('./../../../images/magicItems/dmg/belt_of_giant_strength.png')
const Berserker_axe_DMG_img = require('./../../../images/magicItems/dmg/berserker_axe.jpeg')
const Book_of_exalted_deeds_DMG_img = require('./../../../images/magicItems/dmg/book_of_exalted_deeds.jpeg')
const Book_of_vile_darkness_DMG_img = require('./../../../images/magicItems/dmg/book_of_vile_darkness.jpeg')
const Boots_of_elvenkind_DMG_img = require('./../../../images/magicItems/dmg/boots_of_elvenkind.jpeg')
const Boots_of_speed_DMG_img = require('./../../../images/magicItems/dmg/boots_of_speed.jpeg')
const Boots_of_striding_and_springing_DMG_img = require('./../../../images/magicItems/dmg/boots_of_striding_and_springing.jpeg')
const Boots_of_the_winterlands_DMG_img = require('./../../../images/magicItems/dmg/boots_of_the_winterlands.jpeg')
const Bowl_of_commanding_water_elementals_DMG_img = require('./../../../images/magicItems/dmg/bowl_of_commanding_water_elementals.jpeg')
const Bracers_of_archery_DMG_img = require('./../../../images/magicItems/dmg/bracers_of_archery.jpeg')
const Bracers_of_defense_DMG_img = require('./../../../images/magicItems/dmg/bracers_of_defense.jpeg')
const Brazier_of_commanding_fire_elementals_DMG_img = require('./../../../images/magicItems/dmg/brazier_of_commanding_fire_elementals.jpeg')
const Brooch_of_shielding_DMG_img = require('./../../../images/magicItems/dmg/brooch_of_shielding.jpeg')
const Broom_of_flying_DMG_img = require('./../../../images/magicItems/dmg/broom_of_flying.jpeg')
const Canaith_mandolin_DMG_img = require('./../../../images/magicItems/dmg/canaith_mandolin.jpg')
const Candle_of_invocation_DMG_img = require('./../../../images/magicItems/dmg/candle_of_invocation.jpeg')
const Cape_of_the_mountebank_DMG_img = require('./../../../images/magicItems/dmg/cape_of_the_mountebank.jpeg')
const Censer_of_controlling_air_elementals_DMG_img = require('./../../../images/magicItems/dmg/censer_of_controlling_air_elementals.jpeg')
const Chime_of_opening_DMG_img = require('./../../../images/magicItems/dmg/chime_of_opening.jpeg')
const Circlet_of_blasting_DMG_img = require('./../../../images/magicItems/dmg/circlet_of_blasting.jpeg')
const Cli_lyre_DMG_img = require('./../../../images/magicItems/dmg/cli_lyre.jpg')
const Cloak_of_displacement_DMG_img = require('./../../../images/magicItems/dmg/cloak_of_displacement.jpeg')
const Cloak_of_elvenkind_DMG_img = require('./../../../images/magicItems/dmg/cloak_of_elvenkind.jpeg')
const Cloak_of_protection_DMG_img = require('./../../../images/magicItems/dmg/cloak_of_protection.jpeg')
const Cloak_of_the_manta_ray_DMG_img = require('./../../../images/magicItems/dmg/cloak_of_the_manta_ray.jpeg')
const Cube_of_force_DMG_img = require('./../../../images/magicItems/dmg/cube_of_force.jpeg')
const Cubic_gate_DMG_img = require('./../../../images/magicItems/dmg/cubic_gate.jpeg')
const Daerns_instant_fortress_DMG_img = require('./../../../images/magicItems/dmg/daerns_instant_fortress.jpeg')
const Dagger_of_venom_DMG_img = require('./../../../images/magicItems/dmg/dagger_of_venom.jpeg')
const Dancing_sword_DMG_img = require('./../../../images/magicItems/dmg/dancing_sword.jpeg')
const Decanter_of_endless_water_DMG_img = require('./../../../images/magicItems/dmg/decanter_of_endless_water.jpeg')
const Deck_of_many_things_DMG_img = require('./../../../images/magicItems/dmg/deck_of_many_things.png')
const Defender_DMG_img = require('./../../../images/magicItems/dmg/defender.jpeg')
const Demon_armor_DMG_img = require('./../../../images/magicItems/dmg/demon_armor.jpeg')
const Dimensional_shackles_DMG_img = require('./../../../images/magicItems/dmg/dimensional_shackles.jpeg')
const Dragon_scale_mail_DMG_img = require('./../../../images/magicItems/dmg/dragon_scale_mail.jpeg')
const Dragon_slayer_DMG_img = require('./../../../images/magicItems/dmg/dragon_slayer.jpeg')
const Driftglobe_DMG_img = require('./../../../images/magicItems/dmg/driftglobe.jpeg')
const Dust_of_disappearance_DMG_img = require('./../../../images/magicItems/dmg/dust_of_disappearance.jpeg')
const Dust_of_sneezing_and_choking_DMG_img = require('./../../../images/magicItems/dmg/dust_of_sneezing_and_choking.jpeg')
const Dwarven_plate_DMG_img = require('./../../../images/magicItems/dmg/dwarven_plate.jpeg')
const Dwarven_thrower_DMG_img = require('./../../../images/magicItems/dmg/dwarven_thrower.jpeg')
const Efreeti_bottle_DMG_img = require('./../../../images/magicItems/dmg/efreeti_bottle.jpeg')
const Efreeti_chain_DMG_img = require('./../../../images/magicItems/dmg/efreeti_chain.jpeg')
const Elemental_gem_air_DMG_img = require('./../../../images/magicItems/dmg/elemental_gem_air.jpg')
const Elemental_gem_earth_DMG_img = require('./../../../images/magicItems/dmg/elemental_gem_earth.jpg')
const Elemental_gem_fire_DMG_img = require('./../../../images/magicItems/dmg/elemental_gem_fire.jpg')
const Elemental_gem_water_DMG_img = require('./../../../images/magicItems/dmg/elemental_gem_water.jpg')
const Elven_chain_DMG_img = require('./../../../images/magicItems/dmg/elven_chain.jpeg')
const Eversmoking_bottle_DMG_img = require('./../../../images/magicItems/dmg/eversmoking_bottle.jpeg')
const Eye_and_hand_of_vecna_DMG_img = require('./../../../images/magicItems/dmg/eye_and_hand_of_vecna.jpeg')
const Eyes_of_charming_DMG_img = require('./../../../images/magicItems/dmg/eyes_of_charming.jpeg')
const Eyes_of_the_eagle_DMG_img = require('./../../../images/magicItems/dmg/eyes_of_the_eagle.jpeg')
const Figurine_of_wondrous_power_DMG_img = require('./../../../images/magicItems/dmg/figurine_of_wondrous_power.jpeg')
const Figurine_of_wondrous_power_ebony_fly_DMG_img = require('./../../../images/magicItems/dmg/figurine_of_wondrous_power_ebony_fly.jpg')
const Figurine_of_wondrous_power_golden_lions_DMG_img = require('./../../../images/magicItems/dmg/figurine_of_wondrous_power_golden_lions.jpg')
const Figurine_of_wondrous_power_ivory_goats_DMG_img = require('./../../../images/magicItems/dmg/figurine_of_wondrous_power_ivory_goats.jpg')
const Figurine_of_wondrous_power_marble_elephant_DMG_img = require('./../../../images/magicItems/dmg/figurine_of_wondrous_power_marble_elephant.jpg')
const Figurine_of_wondrous_power_obsidian_steed_DMG_img = require('./../../../images/magicItems/dmg/figurine_of_wondrous_power_obsidian_steed.jpg')
const Figurine_of_wondrous_power_onyx_dog_DMG_img = require('./../../../images/magicItems/dmg/figurine_of_wondrous_power_onyx_dog.jpg')
const Figurine_of_wondrous_power_serpentine_owl_DMG_img = require('./../../../images/magicItems/dmg/figurine_of_wondrous_power_serpentine_owl.jpg')
const Flame_tongue_DMG_img = require('./../../../images/magicItems/dmg/flame_tongue.jpeg')
const Fochlucan_bandore_DMG_img = require('./../../../images/magicItems/dmg/fochlucan_bandore.jpg')
const Folding_boat_DMG_img = require('./../../../images/magicItems/dmg/folding_boat.jpeg')
const Frost_brand_DMG_img = require('./../../../images/magicItems/dmg/frost_brand.jpeg')
const Gauntlets_of_ogre_power_DMG_img = require('./../../../images/magicItems/dmg/gauntlets_of_ogre_power.jpg')
const Gem_of_brightness_DMG_img = require('./../../../images/magicItems/dmg/gem_of_brightness.jpeg')
const Gem_of_seeing_DMG_img = require('./../../../images/magicItems/dmg/gem_of_seeing.jpeg')
const Giant_slayer_DMG_img = require('./../../../images/magicItems/dmg/giant_slayer.jpeg')
const Gloves_of_missile_snaring_DMG_img = require('./../../../images/magicItems/dmg/gloves_of_missile_snaring.jpeg')
const Goggles_of_night_DMG_img = require('./../../../images/magicItems/dmg/goggles_of_night.jpeg')
const Hammer_of_thunderbolts_DMG_img = require('./../../../images/magicItems/dmg/hammer_of_thunderbolts.jpeg')
const Headband_of_intellect_DMG_img = require('./../../../images/magicItems/dmg/headband_of_intellect.jpeg')
const Helm_of_brilliance_DMG_img = require('./../../../images/magicItems/dmg/helm_of_brilliance.jpeg')
const Helm_of_comprehending_languages_DMG_img = require('./../../../images/magicItems/dmg/helm_of_comprehending_languages.jpeg')
const Helm_of_telepathy_DMG_img = require('./../../../images/magicItems/dmg/helm_of_telepathy.jpeg')
const Hewards_handy_haversack_DMG_img = require('./../../../images/magicItems/dmg/hewards_handy_haversack.jpeg')
const Holy_avenger_DMG_img = require('./../../../images/magicItems/dmg/holy_avenger.jpeg')
const Horn_of_blasting_DMG_img = require('./../../../images/magicItems/dmg/horn_of_blasting.jpeg')
const Horn_of_valhalla_DMG_img = require('./../../../images/magicItems/dmg/horn_of_valhalla.jpeg')
const Immovable_rod_DMG_img = require('./../../../images/magicItems/dmg/immovable_rod.jpeg')
const Iron_bands_of_bilarro_DMG_img = require('./../../../images/magicItems/dmg/iron_bands_of_bilarro.jpeg')
const Iron_flask_DMG_img = require('./../../../images/magicItems/dmg/iron_flask.jpeg')
const Javelin_of_lightning_DMG_img = require('./../../../images/magicItems/dmg/javelin_of_lightning.jpeg')
const Keoghtoms_ointment_DMG_img = require('./../../../images/magicItems/dmg/keoghtoms_ointment.jpeg')
const Lantern_of_revealing_DMG_img = require('./../../../images/magicItems/dmg/lantern_of_revealing.jpeg')
const Luck_blade_DMG_img = require('./../../../images/magicItems/dmg/luck_blade.jpeg')
const Mac_fuirmidh_cittern_DMG_img = require('./../../../images/magicItems/dmg/mac_fuirmidh_cittern.jpg')
const Mace_of_disruption_DMG_img = require('./../../../images/magicItems/dmg/mace_of_disruption.jpeg')
const Mace_of_smiting_DMG_img = require('./../../../images/magicItems/dmg/mace_of_smiting.jpeg')
const Mace_of_terror_DMG_img = require('./../../../images/magicItems/dmg/mace_of_terror.jpeg')
const Mantle_of_spell_resistance_DMG_img = require('./../../../images/magicItems/dmg/mantle_of_spell_resistance.jpeg')
const Manual_of_bodily_health_DMG_img = require('./../../../images/magicItems/dmg/manual_of_bodily_health.jpeg')
const Manual_of_golems_DMG_img = require('./../../../images/magicItems/dmg/manual_of_golems.png')
const Manual_of_quickness_of_action_DMG_img = require('./../../../images/magicItems/dmg/manual_of_quickness_of_action.jpeg')
const Medallion_of_thoughts_DMG_img = require('./../../../images/magicItems/dmg/medallion_of_thoughts.jpeg')
const Mirror_of_life_trapping_DMG_img = require('./../../../images/magicItems/dmg/mirror_of_life_trapping.jpeg')
const Necklace_of_adaptation_DMG_img = require('./../../../images/magicItems/dmg/necklace_of_adaptation.jpeg')
const Necklace_of_fireballs_DMG_img = require('./../../../images/magicItems/dmg/necklace_of_fireballs.jpeg')
const Necklace_of_prayer_beads_DMG_img = require('./../../../images/magicItems/dmg/necklace_of_prayer_beads.jpeg')
const Nine_lives_stealer_DMG_img = require('./../../../images/magicItems/dmg/nine_lives_stealer.jpeg')
const Nolzurs_marvelous_pigments_DMG_img = require('./../../../images/magicItems/dmg/nolzurs_marvelous_pigments.jpeg')
const Oathbow_DMG_img = require('./../../../images/magicItems/dmg/oathbow.jpeg')
const Ollamh_harp_DMG_img = require('./../../../images/magicItems/dmg/ollamh_harp.jpg')
const Orb_of_dragonkind_DMG_img = require('./../../../images/magicItems/dmg/orb_of_dragonkind.jpeg')
const Periapt_of_health_DMG_img = require('./../../../images/magicitems/dmg/periapt_of_health.jpeg')
const Periapt_of_wound_closure_DMG_img = require('./../../../images/magicItems/dmg/periapt_of_wound_closure.jpeg')
const Pipes_of_haunting_DMG_img = require('./../../../images/magicItems/dmg/pipes_of_haunting.jpeg')
const Pipes_of_the_sewers_DMG_img = require('./../../../images/magicItems/dmg/pipes_of_the_sewers.jpeg')
const Quaals_feather_token_anchor_DMG_img = require('./../../../images/magicItems/dmg/quaals_feather_token_anchor.jpeg')
const Quaals_feather_token_bird_DMG_img = require('./../../../images/magicItems/dmg/quaals_feather_token_bird.jpeg')
const Quaals_feather_token_fan_DMG_img = require('./../../../images/magicItems/dmg/quaals_feather_token_fan.jpeg')
const Quaals_feather_token_swan_boat_DMG_img = require('./../../../images/magicItems/dmg/quaals_feather_token_swan_boat.jpeg')
const Quaals_feather_token_tree_DMG_img = require('./../../../images/magicItems/dmg/quaals_feather_token_tree.jpeg')
const Quaals_feather_token_whip_DMG_img = require('./../../../images/magicItems/dmg/quaals_feather_token_whip.jpeg')
const Quiver_of_ehlonna_DMG_img = require('./../../../images/magicItems/dmg/quiver_of_ehlonna.jpeg')
const Ring_of_animal_influence_DMG_img = require('./../../../images/magicItems/dmg/ring_of_animal_influence.jpeg')
const Ring_of_djinni_summoning_DMG_img = require('./../../../images/magicItems/dmg/ring_of_djinni_summoning.jpeg')
const Ring_of_elemental_command_air_DMG_img = require('./../../../images/magicItems/dmg/ring_of_elemental_command_air.jpeg')
const Ring_of_evasion_DMG_img = require('./../../../images/magicItems/dmg/ring_of_evasion.jpeg')
const Ring_of_feather_falling_DMG_img = require('./../../../images/magicItems/dmg/ring_of_feather_falling.jpeg')
const Ring_of_free_action_DMG_img = require('./../../../images/magicItems/dmg/ring_of_free_action.jpeg')
const Ring_of_invisibility_DMG_img = require('./../../../images/magicItems/dmg/ring_of_invisibility.jpeg')
const Ring_of_mind_shielding_DMG_img = require('./../../../images/magicItems/dmg/ring_of_mind_shielding.jpeg')
const Ring_of_protection_DMG_img = require('./../../../images/magicItems/dmg/ring_of_protection.jpeg')
const Ring_of_regeneration_DMG_img = require('./../../../images/magicItems/dmg/ring_of_regeneration.jpeg')
const Ring_of_resistance_electricity_DMG_img = require('./../../../images/magicItems/dmg/ring_of_resistance_electricity.jpeg')
const Ring_of_shooting_stars_DMG_img = require('./../../../images/magicItems/dmg/ring_of_shooting_stars.jpeg')
const Ring_of_spell_storing_DMG_img = require('./../../../images/magicItems/dmg/ring_of_spell_storing.jpeg')
const Ring_of_spell_turning_DMG_img = require('./../../../images/magicItems/dmg/ring_of_spell_turning.jpeg')
const Ring_of_telekinesis_DMG_img = require('./../../../images/magicItems/dmg/ring_of_telekinesis.jpeg')
const Ring_of_the_ram_DMG_img = require('./../../../images/magicItems/dmg/ring_of_the_ram.jpeg')
const Ring_of_three_wishes_DMG_img = require('./../../../images/magicItems/dmg/ring_of_three_wishes.jpeg')
const Ring_of_water_walking_DMG_img = require('./../../../images/magicItems/dmg/ring_of_water_walking.jpeg')
const Ring_of_x_ray_vision_DMG_img = require('./../../../images/magicItems/dmg/ring_of_x_ray_vision.jpeg')
const Robe_of_eyes_DMG_img = require('./../../../images/magicItems/dmg/robe_of_eyes.jpeg')
const Robe_of_stars_DMG_img = require('./../../../images/magicItems/dmg/robe_of_stars.jpeg')
const Robe_of_the_archmagi_DMG_img = require('./../../../images/magicItems/dmg/robe_of_the_archmagi.jpeg')
const Robe_of_useful_items_DMG_img = require('./../../../images/magicItems/dmg/robe_of_useful_items.jpeg')
const Rod_of_absorption_DMG_img = require('./../../../images/magicItems/dmg/rod_of_absorption.jpeg')
const Rod_of_lordly_might_DMG_img = require('./../../../images/magicItems/dmg/rod_of_lordly_might.jpeg')
const Rod_of_resurrection_DMG_img = require('./../../../images/magicItems/dmg/rod_of_resurrection.jpeg')
const Rod_of_rulership_DMG_img = require('./../../../images/magicItems/dmg/rod_of_rulership.jpeg')
const Rope_of_entanglement_DMG_img = require('./../../../images/magicItems/dmg/rope_of_entanglement.jpeg')
const Scarab_of_protection_DMG_img = require('./../../../images/magicItems/dmg/scarab_of_protection.jpeg')
const Sending_stones_DMG_img = require('./../../../images/magicItems/dmg/sending_stones.jpeg')
const Shield_of_missile_attraction_DMG_img = require('./../../../images/magicItems/dmg/shield_of_missile_attraction.jpeg')
const Slippers_of_spider_climbing_DMG_img = require('./../../../images/magicItems/dmg/slippers_of_spider_climbing.jpeg')
const Sovereign_glue_DMG_img = require('./../../../images/magicItems/dmg/sovereign_glue.jpeg')
const Spellguard_shield_DMG_img = require('./../../../images/magicItems/dmg/spellguard_shield.jpeg')
const Staff_of_charming_DMG_img = require('./../../../images/magicItems/dmg/staff_of_charming.jpeg')
const Staff_of_fire_DMG_img = require('./../../../images/magicItems/dmg/staff_of_fire.jpeg')
const Staff_of_frost_DMG_img = require('./../../../images/magicItems/dmg/staff_of_frost.jpeg')
const Staff_of_healing_DMG_img = require('./../../../images/magicItems/dmg/staff_of_healing.jpeg')
const Staff_of_power_DMG_img = require('./../../../images/magicItems/dmg/staff_of_power.jpeg')
const Staff_of_swarming_insects_DMG_img = require('./../../../images/magicItems/dmg/staff_of_swarming_insects.jpeg')
const Staff_of_the_adder_DMG_img = require('./../../../images/magicItems/dmg/staff_of_the_adder.jpeg')
const Staff_of_the_magi_DMG_img = require('./../../../images/magicItems/dmg/staff_of_the_magi.jpeg')
const Staff_of_the_python_DMG_img = require('./../../../images/magicItems/dmg/staff_of_the_python.jpeg')
const Staff_of_the_woodlands_DMG_img = require('./../../../images/magicItems/dmg/staff_of_the_woodlands.jpeg')
const Staff_of_thunder_and_lightning_DMG_img = require('./../../../images/magicItems/dmg/staff_of_thunder_and_lightning.jpeg')
const Staff_of_withering_DMG_img = require('./../../../images/magicItems/dmg/staff_of_withering.jpeg')
const Stone_of_controlling_earth_elementals_DMG_img = require('./../../../images/magicItems/dmg/stone_of_controlling_earth_elementals.jpeg')
const Stone_of_good_luck_DMG_img = require('./../../../images/magicItems/dmg/stone_of_good_luck.jpeg')
const Sun_blade_DMG_img = require('./../../../images/magicItems/dmg/sun_blade.jpeg')
const Sword_of_kas_DMG_img = require('./../../../images/magicItems/dmg/sword_of_kas.jpeg')
const Sword_of_life_stealing_DMG_img = require('./../../../images/magicItems/dmg/sword_of_life_stealing.jpeg')
const Sword_of_sharpness_DMG_img = require('./../../../images/magicItems/dmg/sword_of_sharpness.jpeg')
const Talisman_of_the_sphere_DMG_img = require('./../../../images/magicItems/dmg/talisman_of_the_sphere.jpeg')
const Talisman_of_ultimate_evil_DMG_img = require('./../../../images/magicItems/dmg/talisman_of_ultimate_evil.jpeg')
const Tome_of_clear_thought_DMG_img = require('./../../../images/magicItems/dmg/tome_of_clear_thought.jpeg')
const Tome_of_leadership_and_influence_DMG_img = require('./../../../images/magicItems/dmg/tome_of_leadership_and_influence.jpeg')
const Tome_of_understanding_DMG_img = require('./../../../images/magicItems/dmg/tome_of_understanding.jpeg')
const Trident_of_fish_command_DMG_img = require('./../../../images/magicItems/dmg/trident_of_fish_command.jpeg')
const Universal_solvent_DMG_img = require('./../../../images/magicItems/dmg/universal_solvent.jpeg')
const Vicious_weapon_DMG_img = require('./../../../images/magicItems/dmg/vicious_weapon.jpeg')
const Vorpal_sword_DMG_img = require('./../../../images/magicItems/dmg/vorpal_sword.jpeg')
const Wand_of_binding_DMG_img = require('./../../../images/magicItems/dmg/wand_of_binding.jpeg')
const Wand_of_enemy_detection_DMG_img = require('./../../../images/magicItems/dmg/wand_of_enemy_detection.jpeg')
const Wand_of_fear_DMG_img = require('./../../../images/magicItems/dmg/wand_of_fear.jpeg')
const Wand_of_fireballs_DMG_img = require('./../../../images/magicItems/dmg/wand_of_fireballs.jpeg')
const Wand_of_lightning_bolts_DMG_img = require('./../../../images/magicItems/dmg/wand_of_lightning_bolts.jpeg')
const Wand_of_magic_detection_DMG_img = require('./../../../images/magicItems/dmg/wand_of_magic_detection.jpeg')
const Wand_of_magic_missiles_DMG_img = require('./../../../images/magicItems/dmg/wand_of_magic_missiles.jpeg')
const Wand_of_paralysis_DMG_img = require('./../../../images/magicItems/dmg/wand_of_paralysis.jpeg')
const Wand_of_polymorph_DMG_img = require('./../../../images/magicItems/dmg/wand_of_polymorph.jpeg')
const Wand_of_secrets_DMG_img = require('./../../../images/magicItems/dmg/wand_of_secrets.jpeg')
const Wand_of_web_DMG_img = require('./../../../images/magicItems/dmg/wand_of_web.jpeg')
const Wand_of_wonder_DMG_img = require('./../../../images/magicItems/dmg/wand_of_wonder.jpeg')
const Weapon_of_warning_DMG_img = require('./../../../images/magicItems/dmg/weapon_of_warning.jpeg')
const Wind_fan_DMG_img = require('./../../../images/magicItems/dmg/wind_fan.jpeg')
const Winged_boots_DMG_img = require('./../../../images/magicItems/dmg/winged_boots.jpeg')
const Wings_of_flying_DMG_img = require('./../../../images/magicItems/dmg/wings_of_flying.jpeg')
const Wand_of_orcus_DMG_img = require('./../../../images/magicItems/dmg/wand_of_orcus.jpeg')

const Orb_of_Dragonkind_FTD_img = require('./../../../images/magicItems/ftd/orb_of_dragonkind.png')
const Robe_of_the_Archmagi_MTG_CLB_img = require('./../../../images/magicItems/mtg_clb/robe_of_the_archmagi-mtg_clb.jpg')
const Wand_of_Wonder_MTG_CLB_img = require('./../../../images/magicItems/mtg_clb/wand_of_wonder-mtg_clb.jpg')
const Prophetic_Prism_MTG_CLB_img = require('./../../../images/magicItems/mtg_clb/prophetic-prism-mtg_clb.jpg')

const Silver_Raven_MTG_AFR_img = require('./../../../images/magicItems/mtg_afr/silver-raven-mtg_afr.jpg')
const Eye_of_Vecna_MTG_AFR_img = require('./../../../images/magicItems/mtg_afr/eye-of-vecna-mtg_afr.jpg')
const Hand_of_Vecna_MTG_AFR_img = require('./../../../images/magicItems/mtg_afr/hand-of-vecna-mtg_afr.jpg')

const Mystery_key_MTG_CLB_img = require('./../../../images/magicItems/mtg_clb/mystery-key.webp')
const Universal_solvent_MTG_CLB_img = require('./../../../images/magicItems/mtg_clb/universal_solvent_mtg_clb.webp')
const Mirror_of_life_trapping_MTG_CLB_img = require('./../../../images/magicItems/mtg_clb/mirror-of-life-trapping-mtg_clb.webp')
const Lantern_of_revealing_MTG_CLB_img = require('./../../../images/magicItems/mtg_clb/lantern-of-revealing-mtg_CLB.webp')
const Decanter_of_endless_water_MTG_CLB_img = require('./../../../images/magicItems/mtg_clb/decanter_of_endless_water_mtg_clb.webp')
const Bag_of_holding_MTG_CLB_img = require('./../../../images/magicItems/mtg_clb/bag_of_holding_mtg_clb.jpg')
const Javelin_of_lightning_MTG_CLB_img = require('./../../../images/magicItems/mtg_clb/javelin-of-lightning-mtg_clb.webp')

const Bag_of_holding_MTG_M20_img = require('./../../../images/magicItems/mtg_m20/bag_of_holding_mtg_m20.jpg')

const dragonScaleArmor = {
  text: 'Доспех из драконьей чешуи',
  src: Dragon_scale_mail_DMG_img,
}
const hornOfValhalla = {
  text: 'Рог Валгаллы',
  src: Horn_of_valhalla_DMG_img,
}
const beltOfGiantStrength = {
  text: 'Пояс силы великана',
  src: Belt_of_giant_strength_DMG_img,
}
const manualOfGolems = {
  text: 'Справочник по големам',
  src: Manual_of_golems_DMG_img,
}
const bagOfTricks = {
  text: 'Сумка фокусов',
  src: Bag_of_tricks_DMG_img,
}
const eyeAndHandOfVecna = {
  text: 'Глаз и Рука Векны',
  src: Eye_and_hand_of_vecna_DMG_img,
}

const magicItemsImageList_DMG = [
  {
    id: MAGIC_ITEM_MYSTERY_KEY,
    src: Mystery_key_MTG_CLB_img,
    source: {
      id: SOURCE_MTG_CLB,
    },
  },
  {
    id: MAGIC_ITEM_WAND_OF_ORCUS,
    src: Wand_of_orcus_DMG_img,
  },
  {
    id: MAGIC_ITEM_GAUNTLETS_OF_OGRE_POWER,
    src: Gauntlets_of_ogre_power_DMG_img,
  },
  {
    id: MAGIC_ITEM_AXE_OF_THE_DWARVISH_LORDS,
    src: Axe_of_the_dwarvish_lords_DMG_img,
  },
  {
    id: MAGIC_ITEM_ORB_OF_DRAGONKIND,
    src: Orb_of_dragonkind_DMG_img,
  },
  {
    id: MAGIC_ITEM_ORB_OF_DRAGONKIND,
    src: Orb_of_Dragonkind_FTD_img,
    text: 'Полудракон-завоеватель получает Око Дракона, что может погрузить мир в хаос',
    source: {
      id: SOURCE_FTD,
    },
  },
  {
    id: MAGIC_ITEM_SWORD_OF_KAS,
    src: Sword_of_kas_DMG_img,
  },
  {
    id: MAGIC_ITEM_BOOK_OF_VILE_DARKNESS,
    src: Book_of_vile_darkness_DMG_img,
  },
  {
    id: MAGIC_ITEM_BOOK_OF_VILE_DARKNESS,
    ...Vecna_MTG_AFR_data,
  },
  {
    id: MAGIC_ITEM_BOOK_OF_EXALTED_DEEDS,
    src: Book_of_exalted_deeds_DMG_img,
  },
  {
    id: MAGIC_ITEM_HAND_OF_VECNA,
    ...eyeAndHandOfVecna,
  },
  {
    id: MAGIC_ITEM_HAND_OF_VECNA,
    ...Vecna_MTG_AFR_data,
  },
  {
    id: MAGIC_ITEM_HAND_OF_VECNA,
    src: Hand_of_Vecna_MTG_AFR_img,
    source: {
      id: SOURCE_MTG_AFR,
    },
  },
  {
    id: MAGIC_ITEM_EYE_OF_VECNA,
    ...eyeAndHandOfVecna,
  },
  {
    id: MAGIC_ITEM_EYE_OF_VECNA,
    src: Eye_of_Vecna_MTG_AFR_img,
    source: {
      id: SOURCE_MTG_AFR,
    },
  },
  {
    id: MAGIC_ITEM_EYE_OF_VECNA,
    ...Vecna_MTG_AFR_data,
  },
  {
    id: MAGIC_ITEM_FLAME_TONGUE,
    src: Flame_tongue_DMG_img,
  },
  {
    id: MAGIC_ITEM_FLAME_TONGUE,
    ...Dragonborn_Flame_Tongue_AYAGWW_data,
  },
  {
    id: MAGIC_ITEM_CLOAK_OF_ELVENKIND,
    src: Cloak_of_elvenkind_DMG_img,
  },
  {
    id: MAGIC_ITEM_BOOTS_OF_ELVENKIND,
    src: Boots_of_elvenkind_DMG_img,
  },
  {
    id: MAGIC_ITEM_ELVEN_CHAIN,
    src: Elven_chain_DMG_img,
  },
  {
    id: MAGIC_ITEM_SHIELD_OF_MISSILE_ATTRACTION,
    src: Shield_of_missile_attraction_DMG_img,
  },
  {
    id: MAGIC_ITEM_SPELLGUARD_SHIELD,
    src: Spellguard_shield_DMG_img,
  },
  {
    id: MAGIC_ITEM_HELM_OF_TELEPATHY,
    src: Helm_of_telepathy_DMG_img,
  },
  {
    id: MAGIC_ITEM_HELM_OF_COMPREHENDING_LANGUAGES,
    src: Helm_of_comprehending_languages_DMG_img,
  },
  {
    id: MAGIC_ITEM_HELM_OF_BRILLIANCE,
    src: Helm_of_brilliance_DMG_img,
  },
  {
    id: MAGIC_ITEM_NOLZURS_MARVELOUS_PIGMENTS,
    src: Nolzurs_marvelous_pigments_DMG_img,
  },
  {
    id: MAGIC_ITEM_BOWL_OF_COMMANDING_WATER_ELEMENTALS,
    src: Bowl_of_commanding_water_elementals_DMG_img,
  },
  {
    id: MAGIC_ITEM_LANTERN_OF_REVEALING,
    src: Lantern_of_revealing_DMG_img,
  },
  {
    id: MAGIC_ITEM_LANTERN_OF_REVEALING,
    src: Lantern_of_revealing_MTG_CLB_img,
    source: SOURCE_MTG_CLB,
  },
  {
    id: MAGIC_ITEM_UNIVERSAL_SOLVENT,
    src: Universal_solvent_DMG_img,
  },
  {
    id: MAGIC_ITEM_UNIVERSAL_SOLVENT,
    src: Universal_solvent_MTG_CLB_img,
    source: SOURCE_MTG_CLB,
  },
  {
    id: MAGIC_ITEM_HEWARD_S_HANDY_HAVERSACK,
    src: Hewards_handy_haversack_DMG_img,
  },
  {
    id: MAGIC_ITEM_DRAGON_SLAYER,
    src: Dragon_slayer_DMG_img,
  },
  {
    id: MAGIC_ITEM_GIANT_SLAYER,
    src: Giant_slayer_DMG_img,
  },
  {
    id: MAGIC_ITEM_SLIPPERS_OF_SPIDER_CLIMBING,
    src: Slippers_of_spider_climbing_DMG_img,
  },
  {
    id: MAGIC_ITEM_TRIDENT_OF_FISH_COMMAND,
    src: Trident_of_fish_command_DMG_img,
  },
  {
    id: MAGIC_ITEM_BERSERKER_AXE,
    src: Berserker_axe_DMG_img,
  },
  {
    id: MAGIC_ITEM_TOME_OF_CLEAR_THOUGHT,
    src: Tome_of_clear_thought_DMG_img,
  },
  {
    id: MAGIC_ITEM_TOME_OF_UNDERSTANDING,
    src: Tome_of_understanding_DMG_img,
  },
  {
    id: MAGIC_ITEM_TOME_OF_LEADERSHIP_AND_INFLUENCE,
    src: Tome_of_leadership_and_influence_DMG_img,
  },
  {
    id: MAGIC_ITEM_DANCING_SWORD,
    src: Dancing_sword_DMG_img,
  },
  {
    id: MAGIC_ITEM_TALISMAN_OF_THE_SPHERE,
    src: Talisman_of_the_sphere_DMG_img,
  },
  {
    id: MAGIC_ITEM_TALISMAN_OF_ULTIMATE_EVIL,
    src: Talisman_of_ultimate_evil_DMG_img,
  },
  {
    id: MAGIC_ITEM_BAG_OF_HOLDING,
    src: Bag_of_holding_DMG_img,
  },
  {
    id: MAGIC_ITEM_BAG_OF_HOLDING,
    src: Bag_of_holding_MTG_CLB_img,
    source: SOURCE_MTG_CLB,
  },
  {
    id: MAGIC_ITEM_BAG_OF_HOLDING,
    src: Bag_of_holding_MTG_M20_img,
    source: SOURCE_MTG_M20,
  },
  {
    id: MAGIC_ITEM_BAG_OF_TRICKS_GRAY,
    ...bagOfTricks,
  },
  {
    id: MAGIC_ITEM_BAG_OF_TRICKS_RUST,
    ...bagOfTricks,
  },
  {
    id: MAGIC_ITEM_BAG_OF_TRICKS_TAN,
    ...bagOfTricks,
  },
  {
    id: MAGIC_ITEM_BAG_OF_BEANS,
    src: Bag_of_beans_DMG_img,
  },
  {
    id: MAGIC_ITEM_BAG_OF_DEVOURING,
    src: Bag_of_devouring_DMG_img,
  },
  {
    id: MAGIC_ITEM_ARROW_OF_SLAYING,
    src: Arrow_of_slaying_DMG_img,
  },
  {
    id: MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_BRONZE_GRIFFON,
    text: 'Статуэтки чудесной силы',
    src: Figurine_of_wondrous_power_DMG_img,
  },
  {
    id: MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_EBONY_FLY,
    src: Figurine_of_wondrous_power_ebony_fly_DMG_img,
  },
  {
    id: MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_GOLDEN_LIONS,
    src: Figurine_of_wondrous_power_golden_lions_DMG_img,
  },
  {
    id: MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_IVORY_GOATS,
    src: Figurine_of_wondrous_power_ivory_goats_DMG_img,
  },
  {
    id: MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_MARBLE_ELEPHANT,
    src: Figurine_of_wondrous_power_marble_elephant_DMG_img,
  },
  {
    id: MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_OBSIDIAN_STEED,
    src: Figurine_of_wondrous_power_obsidian_steed_DMG_img,
  },
  {
    id: MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_ONYX_DOG,
    src: Figurine_of_wondrous_power_onyx_dog_DMG_img,
  },
  {
    id: MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_SERPENTINE_OWL,
    src: Figurine_of_wondrous_power_serpentine_owl_DMG_img,
  },
  {
    id: MAGIC_ITEM_FIGURINE_OF_WONDROUS_POWER_SILVER_RAVEN,
    src: Silver_Raven_MTG_AFR_img,
    source: {
      id: SOURCE_MTG_AFR,
    },
  },
  {
    id: MAGIC_ITEM_MANUAL_OF_BODILY_HEALTH,
    src: Manual_of_bodily_health_DMG_img,
  },
  {
    id: MAGIC_ITEM_MANUAL_OF_GOLEMS_CLAY,
    ...manualOfGolems,
  },
  {
    id: MAGIC_ITEM_MANUAL_OF_GOLEMS_FLESH,
    ...manualOfGolems,
  },
  {
    id: MAGIC_ITEM_MANUAL_OF_GOLEMS_IRON,
    ...manualOfGolems,
  },
  {
    id: MAGIC_ITEM_MANUAL_OF_GOLEMS_STONE,
    ...manualOfGolems,
  },
  {
    id: MAGIC_ITEM_MANUAL_OF_QUICKNESS_OF_ACTION,
    src: Manual_of_quickness_of_action_DMG_img,
  },
  {
    id: MAGIC_ITEM_SUN_BLADE,
    src: Sun_blade_DMG_img,
  },
  {
    id: MAGIC_ITEM_FOLDING_BOAT,
    src: Folding_boat_DMG_img,
  },
  {
    id: MAGIC_ITEM_SCARAB_OF_PROTECTION,
    src: Scarab_of_protection_DMG_img,
  },
  {
    id: MAGIC_ITEM_HOLY_AVENGER,
    src: Holy_avenger_DMG_img,
  },
  {
    id: MAGIC_ITEM_PIPES_OF_HAUNTING,
    src: Pipes_of_haunting_DMG_img,
  },
  {
    id: MAGIC_ITEM_PIPES_OF_THE_SEWERS,
    src: Pipes_of_the_sewers_DMG_img,
  },
  {
    id: MAGIC_ITEM_CANDLE_OF_INVOCATION,
    src: Candle_of_invocation_DMG_img,
  },
  {
    id: MAGIC_ITEM_BOOTS_OF_STRIDING_AND_SPRINGING,
    src: Boots_of_striding_and_springing_DMG_img,
  },
  {
    id: MAGIC_ITEM_BOOTS_OF_SPEED,
    src: Boots_of_speed_DMG_img,
  },
  {
    id: MAGIC_ITEM_HORN_OF_BLASTING,
    src: Horn_of_blasting_DMG_img,
  },
  {
    id: MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_HILL,
    ...beltOfGiantStrength,
  },
  {
    id: MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_STONE,
    ...beltOfGiantStrength,
  },
  {
    id: MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_FROST,
    ...beltOfGiantStrength,
  },
  {
    id: MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_FIRE,
    ...beltOfGiantStrength,
  },
  {
    id: MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_CLOUD,
    ...beltOfGiantStrength,
  },
  {
    id: MAGIC_ITEM_BELT_OF_GIANT_STRENGTH_STORM,
    ...beltOfGiantStrength,
  },
  {
    id: MAGIC_ITEM_HORN_OF_VALHALLA_BRONZE,
    ...hornOfValhalla,
  },
  {
    id: MAGIC_ITEM_HORN_OF_VALHALLA_BRASS,
    ...hornOfValhalla,
  },
  {
    id: MAGIC_ITEM_HORN_OF_VALHALLA_IRON,
    ...hornOfValhalla,
  },
  {
    id: MAGIC_ITEM_HORN_OF_VALHALLA_SILVER,
    ...hornOfValhalla,
  },
  {
    id: MAGIC_ITEM_SOVEREIGN_GLUE,
    src: Sovereign_glue_DMG_img,
  },
  {
    id: MAGIC_ITEM_BELT_OF_DWARVENKIND,
    src: Belt_of_dwarvenkind_DMG_img,
  },
  {
    id: MAGIC_ITEM_STAFF_OF_POWER,
    src: Staff_of_power_DMG_img,
  },
  {
    id: MAGIC_ITEM_STAFF_OF_SWARMING_INSECTS,
    src: Staff_of_swarming_insects_DMG_img,
  },
  {
    id: MAGIC_ITEM_STAFF_OF_THE_PYTHON,
    src: Staff_of_the_python_DMG_img,
  },
  {
    id: MAGIC_ITEM_STAFF_OF_CHARMING,
    src: Staff_of_charming_DMG_img,
  },
  {
    id: MAGIC_ITEM_STAFF_OF_FIRE,
    src: Staff_of_fire_DMG_img,
  },
  {
    id: MAGIC_ITEM_STAFF_OF_FROST,
    src: Staff_of_frost_DMG_img,
  },
  {
    id: MAGIC_ITEM_STAFF_OF_THE_MAGI,
    src: Staff_of_the_magi_DMG_img,
  },
  {
    id: MAGIC_ITEM_STAFF_OF_HEALING,
    src: Staff_of_healing_DMG_img,
  },
  {
    id: MAGIC_ITEM_STAFF_OF_THE_WOODLANDS,
    src: Staff_of_the_woodlands_DMG_img,
  },
  {
    id: MAGIC_ITEM_STAFF_OF_WITHERING,
    src: Staff_of_withering_DMG_img,
  },
  {
    id: MAGIC_ITEM_STAFF_OF_THUNDER_AND_LIGHTNING,
    src: Staff_of_thunder_and_lightning_DMG_img,
  },
  {
    id: MAGIC_ITEM_STAFF_OF_THE_ADDER,
    src: Staff_of_the_adder_DMG_img,
  },
  {
    id: MAGIC_ITEM_DUST_OF_SNEEZING_AND_CHOKING,
    src: Dust_of_sneezing_and_choking_DMG_img,
  },
  {
    id: MAGIC_ITEM_DUST_OF_DISAPPEARANCE,
    src: Dust_of_disappearance_DMG_img,
  },
  {
    id: MAGIC_ITEM_BROOM_OF_FLYING,
    src: Broom_of_flying_DMG_img,
  },
  {
    id: MAGIC_ITEM_HEADBAND_OF_INTELLECT,
    src: Headband_of_intellect_DMG_img,
  },
  {
    id: MAGIC_ITEM_CAPE_OF_THE_MOUNTEBANK,
    src: Cape_of_the_mountebank_DMG_img,
  },
  {
    id: MAGIC_ITEM_CLOAK_OF_DISPLACEMENT,
    src: Cloak_of_displacement_DMG_img,
  },
  {
    id: MAGIC_ITEM_CLOAK_OF_THE_MANTA_RAY,
    src: Cloak_of_the_manta_ray_DMG_img,
  },
  {
    id: MAGIC_ITEM_CLOAK_OF_PROTECTION,
    src: Cloak_of_protection_DMG_img,
  },
  {
    id: MAGIC_ITEM_GLOVES_OF_MISSILE_SNARING,
    src: Gloves_of_missile_snaring_DMG_img,
  },
  {
    id: MAGIC_ITEM_QUAALS_FEATHER_TOKEN_ANCHOR,
    src: Quaals_feather_token_anchor_DMG_img,
  },
  {
    id: MAGIC_ITEM_QUAALS_FEATHER_TOKEN_BIRD,
    src: Quaals_feather_token_bird_DMG_img,
  },
  {
    id: MAGIC_ITEM_QUAALS_FEATHER_TOKEN_FAN,
    src: Quaals_feather_token_fan_DMG_img,
  },
  {
    id: MAGIC_ITEM_QUAALS_FEATHER_TOKEN_SWAN_BOAT,
    src: Quaals_feather_token_swan_boat_DMG_img,
  },
  {
    id: MAGIC_ITEM_QUAALS_FEATHER_TOKEN_TREE,
    src: Quaals_feather_token_tree_DMG_img,
  },
  {
    id: MAGIC_ITEM_QUAALS_FEATHER_TOKEN_WHIP,
    src: Quaals_feather_token_whip_DMG_img,
  },
  {
    id: MAGIC_ITEM_DRIFTGLOBE,
    src: Driftglobe_DMG_img,
  },
  {
    id: MAGIC_ITEM_EYES_OF_CHARMING,
    src: Eyes_of_charming_DMG_img,
  },
  {
    id: MAGIC_ITEM_EYES_OF_THE_EAGLE,
    src: Eyes_of_the_eagle_DMG_img,
  },
  {
    id: MAGIC_ITEM_WEAPON_OF_WARNING,
    src: Weapon_of_warning_DMG_img,
  },
  {
    id: MAGIC_ITEM_DIMENSIONAL_SHACKLES,
    src: Dimensional_shackles_DMG_img,
  },
  {
    id: MAGIC_ITEM_NECKLACE_OF_FIREBALLS,
    src: Necklace_of_fireballs_DMG_img,
  },
  {
    id: MAGIC_ITEM_NECKLACE_OF_PRAYER_BEADS,
    src: Necklace_of_prayer_beads_DMG_img,
  },
  {
    id: MAGIC_ITEM_NECKLACE_OF_ADAPTATION,
    src: Necklace_of_adaptation_DMG_img,
  },
  {
    id: MAGIC_ITEM_CIRCLET_OF_BLASTING,
    src: Circlet_of_blasting_DMG_img,
  },
  {
    id: MAGIC_ITEM_GOGGLES_OF_NIGHT,
    src: Goggles_of_night_DMG_img,
  },
  {
    id: MAGIC_ITEM_IMMOVABLE_ROD,
    src: Immovable_rod_DMG_img,
  },
  {
    id: MAGIC_ITEM_BRACERS_OF_ARCHERY,
    src: Bracers_of_archery_DMG_img,
  },
  {
    id: MAGIC_ITEM_BRACERS_OF_DEFENSE,
    src: Bracers_of_defense_DMG_img,
  },
  {
    id: MAGIC_ITEM_FROST_BRAND,
    src: Frost_brand_DMG_img,
  },
  {
    id: MAGIC_ITEM_SWORD_OF_SHARPNESS,
    src: Sword_of_sharpness_DMG_img,
  },
  {
    id: MAGIC_ITEM_HAMMER_OF_THUNDERBOLTS,
    src: Hammer_of_thunderbolts_DMG_img,
  },
  {
    id: MAGIC_ITEM_SWORD_OF_LIFE_STEALING,
    src: Sword_of_life_stealing_DMG_img,
  },
  {
    id: MAGIC_ITEM_VORPAL_SWORD,
    src: Vorpal_sword_DMG_img,
  },
  {
    id: MAGIC_ITEM_JAVELIN_OF_LIGHTNING,
    src: Javelin_of_lightning_DMG_img,
  },
  {
    id: MAGIC_ITEM_JAVELIN_OF_LIGHTNING,
    src: Javelin_of_lightning_MTG_CLB_img,
    source: SOURCE_MTG_CLB,
  },
  {
    id: MAGIC_ITEM_MEDALLION_OF_THOUGHTS,
    src: Medallion_of_thoughts_DMG_img,
  },
  {
    id: MAGIC_ITEM_PERIAPT_OF_HEALTH,
    src: Periapt_of_health_DMG_img,
  },
  {
    id: MAGIC_ITEM_PERIAPT_OF_WOUND_CLOSURE,
    src: Periapt_of_wound_closure_DMG_img,
  },
  {
    id: MAGIC_ITEM_DAERNS_INSTANT_FORTRESS,
    src: Daerns_instant_fortress_DMG_img,
  },
  {
    id: MAGIC_ITEM_MANTLE_OF_SPELL_RESISTANCE,
    src: Mantle_of_spell_resistance_DMG_img,
  },
  {
    id: MAGIC_ITEM_ROBE_OF_USEFUL_ITEMS,
    src: Robe_of_useful_items_DMG_img,
  },
  {
    id: MAGIC_ITEM_ROBE_OF_STARS,
    src: Robe_of_stars_DMG_img,
  },
  {
    id: MAGIC_ITEM_ROBE_OF_EYES,
    src: Robe_of_eyes_DMG_img,
  },
  {
    id: MAGIC_ITEM_ROBE_OF_THE_ARCHMAGI,
    src: Robe_of_the_archmagi_DMG_img,
  },
  {
    id: MAGIC_ITEM_ROBE_OF_THE_ARCHMAGI,
    src: Robe_of_the_Archmagi_MTG_CLB_img,
    source: {
      id: SOURCE_MTG_CLB,
    },
  },
  {
    id: MAGIC_ITEM_KEOGHTOM_S_OINTMENT,
    src: Keoghtoms_ointment_DMG_img,
  },
  {
    id: MAGIC_ITEM_OATHBOW,
    src: Oathbow_DMG_img,
  },
  {
    id: MAGIC_ITEM_DWARVEN_PLATE,
    src: Dwarven_plate_DMG_img,
  },
  {
    id: MAGIC_ITEM_CUBE_OF_FORCE,
    src: Cube_of_force_DMG_img,
  },
  {
    id: MAGIC_ITEM_CUBIC_GATE,
    src: Cubic_gate_DMG_img,
  },
  {
    id: MAGIC_ITEM_WINGS_OF_FLYING,
    src: Wings_of_flying_DMG_img,
  },
  {
    id: MAGIC_ITEM_WINGED_BOOTS,
    src: Winged_boots_DMG_img,
  },
  {
    id: MAGIC_ITEM_EFREETI_CHAIN,
    src: Efreeti_chain_DMG_img,
  },
  {
    id: MAGIC_ITEM_RING_OF_SPELL_STORING,
    src: Ring_of_spell_storing_DMG_img,
  },
  {
    id: MAGIC_ITEM_RING_OF_WATER_WALKING,
    src: Ring_of_water_walking_DMG_img,
  },
  {
    id: MAGIC_ITEM_RING_OF_EVASION,
    src: Ring_of_evasion_DMG_img,
  },
  {
    id: MAGIC_ITEM_RING_OF_THREE_WISHES,
    src: Ring_of_three_wishes_DMG_img,
  },
  {
    id: MAGIC_ITEM_RING_OF_TELEKINESIS,
    src: Ring_of_telekinesis_DMG_img,
  },
  {
    id: MAGIC_ITEM_RING_OF_THE_RAM,
    src: Ring_of_the_ram_DMG_img,
  },
  {
    id: MAGIC_ITEM_RING_OF_RESISTANCE_ELECTRICITY,
    src: Ring_of_resistance_electricity_DMG_img,
  },
  {
    id: MAGIC_ITEM_RING_OF_FREE_ACTION,
    src: Ring_of_free_action_DMG_img,
  },
  {
    id: MAGIC_ITEM_RING_OF_REGENERATION,
    src: Ring_of_regeneration_DMG_img,
  },
  {
    id: MAGIC_ITEM_RING_OF_X_RAY_VISION,
    src: Ring_of_x_ray_vision_DMG_img,
  },
  {
    id: MAGIC_ITEM_RING_OF_DJINNI_SUMMONING,
    src: Ring_of_djinni_summoning_DMG_img,
  },
  {
    id: MAGIC_ITEM_RING_OF_FEATHER_FALLING,
    src: Ring_of_feather_falling_DMG_img,
  },
  {
    id: MAGIC_ITEM_RING_OF_SHOOTING_STARS,
    src: Ring_of_shooting_stars_DMG_img,
  },
  {
    id: MAGIC_ITEM_RING_OF_SPELL_TURNING,
    src: Ring_of_spell_turning_DMG_img,
  },
  {
    id: MAGIC_ITEM_RING_OF_INVISIBILITY,
    src: Ring_of_invisibility_DMG_img,
  },
  {
    id: MAGIC_ITEM_RING_OF_ELEMENTAL_COMMAND_AIR,
    src: Ring_of_elemental_command_air_DMG_img,
  },
  {
    id: MAGIC_ITEM_RING_OF_MIND_SHIELDING,
    src: Ring_of_mind_shielding_DMG_img,
  },
  {
    id: MAGIC_ITEM_RING_OF_PROTECTION,
    src: Ring_of_protection_DMG_img,
  },
  {
    id: MAGIC_ITEM_RING_OF_ANIMAL_INFLUENCE,
    src: Ring_of_animal_influence_DMG_img,
  },
  {
    id: MAGIC_ITEM_QUIVER_OF_EHLONNA,
    src: Quiver_of_ehlonna_DMG_img,
  },
  {
    id: MAGIC_ITEM_CHIME_OF_OPENING,
    src: Chime_of_opening_DMG_img,
  },
  {
    id: MAGIC_ITEM_DECK_OF_MANY_THINGS,
    src: Deck_of_many_things_DMG_img,
  },
  {
    id: MAGIC_ITEM_LUCK_BLADE,
    src: Luck_blade_DMG_img,
  },
  {
    id: MAGIC_ITEM_DAGGER_OF_VENOM,
    src: Dagger_of_venom_DMG_img,
  },
  {
    id: MAGIC_ITEM_SENDING_STONES,
    src: Sending_stones_DMG_img,
  },
  {
    id: MAGIC_ITEM_ELEMENTAL_GEM_AIR,
    Elemental_gem_air_DMG_img,
  },
  {
    id: MAGIC_ITEM_ELEMENTAL_GEM_EARTH,
    Elemental_gem_earth_DMG_img,
  },
  {
    id: MAGIC_ITEM_ELEMENTAL_GEM_WATER,
    Elemental_gem_fire_DMG_img,
  },
  {
    id: MAGIC_ITEM_ELEMENTAL_GEM_FIRE,
    Elemental_gem_water_DMG_img,
  },
  {
    id: MAGIC_ITEM_STONE_OF_GOOD_LUCK,
    src: Stone_of_good_luck_DMG_img,
  },
  {
    id: MAGIC_ITEM_GEM_OF_BRIGHTNESS,
    src: Gem_of_brightness_DMG_img,
  },
  {
    id: MAGIC_ITEM_STONE_OF_CONTROLLING_EARTH_ELEMENTALS,
    src: Stone_of_controlling_earth_elementals_DMG_img,
  },
  {
    id: MAGIC_ITEM_GEM_OF_SEEING,
    src: Gem_of_seeing_DMG_img,
  },
  {
    id: MAGIC_ITEM_GEM_OF_SEEING,
    text: 'Призма прорицания',
    src: Prophetic_Prism_MTG_CLB_img,
    source: {
      id: SOURCE_MTG_CLB,
    },
  },
  {
    id: MAGIC_ITEM_CENSER_OF_CONTROLLING_AIR_ELEMENTALS,
    src: Censer_of_controlling_air_elementals_DMG_img,
  },
  {
    id: MAGIC_ITEM_ANSTRUTH_HARP,
    src: Anstruth_harp_DMG_img,
  },
  {
    id: MAGIC_ITEM_DOSS_LUTE,
    ...Doss_lute_DMG_data,
  },
  {
    id: MAGIC_ITEM_CANAITH_MANDOLIN,
    src: Canaith_mandolin_DMG_img,
  },
  {
    id: MAGIC_ITEM_CLI_LYRE,
    src: Cli_lyre_DMG_img,
  },
  {
    id: MAGIC_ITEM_OLLAMH_HARP,
    src: Ollamh_harp_DMG_img,
  },
  {
    id: MAGIC_ITEM_FOCHLUCAN_BANDORE,
    src: Fochlucan_bandore_DMG_img,
  },
  {
    id: MAGIC_ITEM_MAC_FUIRMIDH_CITTERN,
    src: Mac_fuirmidh_cittern_DMG_img,
  },
  {
    id: MAGIC_ITEM_MIRROR_OF_LIFE_TRAPPING,
    src: Mirror_of_life_trapping_DMG_img,
  },
  {
    id: MAGIC_ITEM_MIRROR_OF_LIFE_TRAPPING,
    src: Mirror_of_life_trapping_MTG_CLB_img,
    source: SOURCE_MTG_CLB,
  },
  {
    id: MAGIC_ITEM_DEFENDER,
    src: Defender_DMG_img,
  },
  {
    id: MAGIC_ITEM_BOOTS_OF_THE_WINTERLANDS,
    src: Boots_of_the_winterlands_DMG_img,
  },
  {
    id: MAGIC_ITEM_ANIMATED_SHIELD,
    src: Animated_shield_DMG_img,
  },
  {
    id: MAGIC_ITEM_VICIOUS_WEAPON,
    src: Vicious_weapon_DMG_img,
  },
  {
    id: MAGIC_ITEM_IRON_BANDS_OF_BILARRO,
    src: Iron_bands_of_bilarro_DMG_img,
  },
  {
    id: MAGIC_ITEM_IRON_FLASK,
    src: Iron_flask_DMG_img,
  },
  {
    id: MAGIC_ITEM_ROD_OF_RULERSHIP,
    src: Rod_of_rulership_DMG_img,
  },
  {
    id: MAGIC_ITEM_ROD_OF_ABSORPTION,
    src: Rod_of_absorption_DMG_img,
  },
  {
    id: MAGIC_ITEM_ROD_OF_RESURRECTION,
    src: Rod_of_resurrection_DMG_img,
  },
  {
    id: MAGIC_ITEM_ROD_OF_LORDLY_MIGHT,
    src: Rod_of_lordly_might_DMG_img,
  },
  {
    id: MAGIC_ITEM_BRAZIER_OF_COMMANDING_FIRE_ELEMENTALS,
    src: Brazier_of_commanding_fire_elementals_DMG_img,
  },
  {
    id: MAGIC_ITEM_ARMOR_OF_RESISTANCE,
    text: 'Доспех сопротивления',
    src: Armor_of_resistance_DMG_img,
  },
  {
    id: MAGIC_ITEM_ARMOR_OF_INVULNERABILITY,
    src: Armor_of_invulnerability_DMG_img,
  },
  {
    id: MAGIC_ITEM_DRAGON_SCALE_MAIL_BLACK,
    ...dragonScaleArmor,
  },
  {
    id: MAGIC_ITEM_DRAGON_SCALE_MAIL_BLUE,
    ...dragonScaleArmor,
  },
  {
    id: MAGIC_ITEM_DRAGON_SCALE_MAIL_BRASS,
    ...dragonScaleArmor,
  },
  {
    id: MAGIC_ITEM_DRAGON_SCALE_MAIL_BRONZE,
    ...dragonScaleArmor,
  },
  {
    id: MAGIC_ITEM_DRAGON_SCALE_MAIL_COPPER,
    ...dragonScaleArmor,
  },
  {
    id: MAGIC_ITEM_DRAGON_SCALE_MAIL_GOLD,
    ...dragonScaleArmor,
  },
  {
    id: MAGIC_ITEM_DRAGON_SCALE_MAIL_GREEN,
    ...dragonScaleArmor,
  },
  {
    id: MAGIC_ITEM_DRAGON_SCALE_MAIL_RED,
    ...dragonScaleArmor,
  },
  {
    id: MAGIC_ITEM_DRAGON_SCALE_MAIL_SILVER,
    ...dragonScaleArmor,
  },
  {
    id: MAGIC_ITEM_DRAGON_SCALE_MAIL_WHITE,
    ...dragonScaleArmor,
  },
  {
    id: MAGIC_ITEM_DEMON_ARMOR,
    src: Demon_armor_DMG_img,
  },
  {
    id: MAGIC_ITEM_DWARVEN_THROWER,
    src: Dwarven_thrower_DMG_img,
  },
  {
    id: MAGIC_ITEM_DECANTER_OF_ENDLESS_WATER,
    src: Decanter_of_endless_water_DMG_img,
  },
  {
    id: MAGIC_ITEM_DECANTER_OF_ENDLESS_WATER,
    src: Decanter_of_endless_water_MTG_CLB_img,
    source: SOURCE_MTG_CLB,
  },
  {
    id: MAGIC_ITEM_NINE_LIVES_STEALER,
    src: Nine_lives_stealer_DMG_img,
  },
  {
    id: MAGIC_ITEM_WAND_OF_WONDER,
    src: Wand_of_wonder_DMG_img,
  },
  {
    id: MAGIC_ITEM_WAND_OF_WONDER,
    src: Wand_of_Wonder_MTG_CLB_img,
    source: {
      id: SOURCE_MTG_CLB,
    },
  },
  {
    id: MAGIC_ITEM_WAND_OF_FEAR,
    src: Wand_of_fear_DMG_img,
  },
  {
    id: MAGIC_ITEM_WAND_OF_MAGIC_MISSILES,
    src: Wand_of_magic_missiles_DMG_img,
  },
  {
    id: MAGIC_ITEM_WAND_OF_BINDING,
    src: Wand_of_binding_DMG_img,
  },
  {
    id: MAGIC_ITEM_WAND_OF_SECRETS,
    src: Wand_of_secrets_DMG_img,
  },
  {
    id: MAGIC_ITEM_WAND_OF_POLYMORPH,
    src: Wand_of_polymorph_DMG_img,
  },
  {
    id: MAGIC_ITEM_WAND_OF_WEB,
    src: Wand_of_web_DMG_img,
  },
  {
    id: MAGIC_ITEM_ALCHEMY_JUG,
    src: Alchemy_jug_DMG_img,
  },
  {
    id: MAGIC_ITEM_AMULET_OF_PROOF_AGAINST_DETECTION_AND_LOCATION,
    src: Amulet_of_proof_against_detection_and_location_DMG_img,
  },
  {
    id: MAGIC_ITEM_AMULET_OF_HEALTH,
    src: Amulet_of_health_DMG_img,
  },
  {
    id: MAGIC_ITEM_AMULET_OF_THE_PLANES,
    src: Amulet_of_the_planes_DMG_img,
  },
  {
    id: MAGIC_ITEM_APPARATUS_OF_KWALISH,
    src: Apparatus_of_kwalish_DMG_img,
  },
  {
    id: MAGIC_ITEM_BROOCH_OF_SHIELDING,
    src: Brooch_of_shielding_DMG_img,
  },
  {
    id: MAGIC_ITEM_MACE_OF_SMITING,
    src: Mace_of_smiting_DMG_img,
  },
  {
    id: MAGIC_ITEM_MACE_OF_DISRUPTION,
    src: Mace_of_disruption_DMG_img,
  },
  {
    id: MAGIC_ITEM_MACE_OF_TERROR,
    src: Mace_of_terror_DMG_img,
  },
  {
    id: MAGIC_ITEM_EFREETI_BOTTLE,
    src: Efreeti_bottle_DMG_img,
  },
  {
    id: MAGIC_ITEM_WIND_FAN,
    src: Wind_fan_DMG_img,
  },
  {
    id: MAGIC_ITEM_ROPE_OF_ENTANGLEMENT,
    src: Rope_of_entanglement_DMG_img,
  },
  {
    id: MAGIC_ITEM_EVERSMOKING_BOTTLE,
    src: Eversmoking_bottle_DMG_img,
  },
  {
    id: MAGIC_ITEM_WAND_OF_LIGHTNING_BOLTS,
    src: Wand_of_lightning_bolts_DMG_img,
  },
  {
    id: MAGIC_ITEM_WAND_OF_ENEMY_DETECTION,
    src: Wand_of_enemy_detection_DMG_img,
  },
  {
    id: MAGIC_ITEM_WAND_OF_MAGIC_DETECTION,
    src: Wand_of_magic_detection_DMG_img,
  },
  {
    id: MAGIC_ITEM_WAND_OF_FIREBALLS,
    src: Wand_of_fireballs_DMG_img,
  },
  {
    id: MAGIC_ITEM_WAND_OF_PARALYSIS,
    src: Wand_of_paralysis_DMG_img,
  },
  {
    id: MAGIC_ITEM_POTION_OF_HEALING,
    ...Potion_of_healing_AYAGWW_data,
  },
  {
    id: MAGIC_ITEM_POTION_OF_HEALING,
    ...Potion_of_Healing_MTG_AFR_data,
  },
  {
    id: MAGIC_ITEM_POTION_OF_HEALING_COMMON,
    ...Potion_of_healing_AYAGWW_data,
  },
  {
    id: MAGIC_ITEM_POTION_OF_HEALING_COMMON,
    ...Potion_of_Healing_MTG_AFR_data,
  },
  {
    id: MAGIC_ITEM_POTION_OF_GREATER_HEALING,
    ...Potion_of_healing_AYAGWW_data,
  },
  {
    id: MAGIC_ITEM_POTION_OF_GREATER_HEALING,
    ...Potion_of_Healing_MTG_AFR_data,
  },
  {
    id: MAGIC_ITEM_POTION_OF_SUPREME_HEALING,
    ...Potion_of_healing_AYAGWW_data,
  },
  {
    id: MAGIC_ITEM_POTION_OF_SUPREME_HEALING,
    ...Potion_of_Healing_MTG_AFR_data,
  },
  {
    id: MAGIC_ITEM_POTION_OF_SUPERIOR_HEALING,
    ...Potion_of_healing_AYAGWW_data,
  },
  {
    id: MAGIC_ITEM_POTION_OF_SUPERIOR_HEALING,
    ...Potion_of_Healing_MTG_AFR_data,
  },
]
  .map(
    gear => ({
      ...gear,
      source: gear.source || {id: SOURCE_DMG},
    })
  )

module.exports = listToCollectionById(magicItemsImageList_DMG)
