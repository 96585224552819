const listToCollectionById = require('./../../../utils/listToCollectionById')
const { SOURCE_TCoE } = require('./../../sourceList')
const {
  MAGIC_ITEM_ABSORBING_TATTOO,
  MAGIC_ITEM_ABSORBING_TATTOO_BLACK,
  MAGIC_ITEM_ABSORBING_TATTOO_BLUE,
  MAGIC_ITEM_ABSORBING_TATTOO_GOLD,
  MAGIC_ITEM_ABSORBING_TATTOO_GREEN,
  MAGIC_ITEM_ABSORBING_TATTOO_ORANGE,
  MAGIC_ITEM_ABSORBING_TATTOO_RED,
  MAGIC_ITEM_ABSORBING_TATTOO_SILVER,
  MAGIC_ITEM_ABSORBING_TATTOO_VIOLET,
  MAGIC_ITEM_ABSORBING_TATTOO_WHITE,
  MAGIC_ITEM_ABSORBING_TATTOO_YELLOW,
  MAGIC_ITEM_ATLAS_OF_ENDLESS_HORIZONS,
  MAGIC_ITEM_BABA_YAGA_S_MORTAR_AND_PESTLE,
  MAGIC_ITEM_CAULDRON_OF_REBIRTH,
  MAGIC_ITEM_CROOK_OF_RAO,
  MAGIC_ITEM_CRYSTALLINE_CHRONICLE,
} = require('./../../magicItemIdList')
const {
  Orc_tattoo_Elf_TCoE_data,
} = require('./../commonImageCollection')
const Atlas_of_Endless_Horizons_TCOE_img = require('./../../../images/magicItems/tcoe/atlas_of_endless_horizons.png')
const Baba_Yaga_s_mortar_and_pestle_TCOE_img = require('./../../../images/magicItems/tcoe/baba_yaga_s_mortar_and_pestle.png')
const Cauldron_of_Rebirth_TCOE_img = require('./../../../images/magicItems/tcoe/cauldron_of_rebirth.png')
const Crook_of_Rao_TCOE_img = require('./../../../images/magicItems/tcoe/crook_of_rao.png')
const Crystalline_Chronicle_TCOE_img = require('./../../../images/magicItems/tcoe/crystalline_chronicle.png')

module.exports = listToCollectionById([
  ...[
    MAGIC_ITEM_ABSORBING_TATTOO,
    MAGIC_ITEM_ABSORBING_TATTOO_BLACK,
    MAGIC_ITEM_ABSORBING_TATTOO_BLUE,
    MAGIC_ITEM_ABSORBING_TATTOO_GOLD,
    MAGIC_ITEM_ABSORBING_TATTOO_GREEN,
    MAGIC_ITEM_ABSORBING_TATTOO_ORANGE,
    MAGIC_ITEM_ABSORBING_TATTOO_RED,
    MAGIC_ITEM_ABSORBING_TATTOO_SILVER,
    MAGIC_ITEM_ABSORBING_TATTOO_VIOLET,
    MAGIC_ITEM_ABSORBING_TATTOO_WHITE,
    MAGIC_ITEM_ABSORBING_TATTOO_YELLOW,
  ].map(
    id => ({
      ...Orc_tattoo_Elf_TCoE_data,
      id,
    })
  ),
  ...[
    {
      id: MAGIC_ITEM_ATLAS_OF_ENDLESS_HORIZONS,
      src: Atlas_of_Endless_Horizons_TCOE_img,
    },
    {
      id: MAGIC_ITEM_BABA_YAGA_S_MORTAR_AND_PESTLE,
      text: 'Ночная карга взяла Ступу и Пест Бабы Яги покататься',
      src: Baba_Yaga_s_mortar_and_pestle_TCOE_img,
    },
    {
      id: MAGIC_ITEM_CAULDRON_OF_REBIRTH,
      src: Cauldron_of_Rebirth_TCOE_img,
    },
    {
      id: MAGIC_ITEM_CROOK_OF_RAO,
      src: Crook_of_Rao_TCOE_img,
    },
    {
      id: MAGIC_ITEM_CRYSTALLINE_CHRONICLE,
      src: Crystalline_Chronicle_TCOE_img,
    },
  ]
    .map(
      gear => ({
        ...gear,
        source: {
          id: SOURCE_TCoE,
        },
      })
    ),
])
