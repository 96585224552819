import React from 'react'
import PropTypes from 'prop-types'

import ImageList from '@/components/ImageList'

import magicItemImageCollection from '@/constants/images/magicItemImageCollection'
import {magicItemCollection} from '@/constants/magicItemList'

const MagicItemImageListContainer = ({ id }) => {
  if (id) {
    const magicItem = magicItemCollection[id]
    const imageList = magicItemImageCollection[id]

    if (imageList) {
      return (
        <ImageList
          imageList={imageList}
          {...magicItem}
        />
      )
    }

    return null
  }

  return null
}

MagicItemImageListContainer.propTypes = {
  id: PropTypes.string.isRequired,
}

export default MagicItemImageListContainer
