import React from 'react'

import { magicItemCollection } from '@/constants/magicItemList'

import generateMagicItemPageUrlById from '@/utils/generateMagicItemPageUrlById'
import sortByName from '@/utils/sortByName'

import MagicItemList from './MagicItemList'

const MagicItemSiblingList = ({idList, currentId}) => {
  if (idList && idList.length) {
    const list = idList
      .map(
        id => {
          const {name} = magicItemCollection[id]
          const url = currentId === id
            ? null
            : generateMagicItemPageUrlById(id)

          return {
            id,
            name,
            url,
          }
        }
      )
      .sort(sortByName)

    return (
      <MagicItemList
        headerText='Аналоги'
        list={list}
      />
    )
  }

  return null
}

export default MagicItemSiblingList
