import React from 'react'

import MagicItem from '@/components/MagicItem'
import MagicItemCatalog from '@/components/MagicItemCatalog'

import {magicItemCollection} from "@/constants/magicItemList"
import magicItemImageCollection from '@/constants/images/magicItemImageCollection'

import CommonCatalogPageTemplate from '@/templates/CommonCatalogPageTemplate'

const defaultSeoData = {
  title: 'Магические предметы',
  description: 'Каталог всех магических предметов в Dungeons & Dragons',
  img: null,
}

const MagicItemPageTemplate = (props) => (
  <CommonCatalogPageTemplate
    defaultSeoData={defaultSeoData}
    itemCollection={magicItemCollection}
    ItemComponent={MagicItem}
    itemImageCollection={magicItemImageCollection}
    ListComponent={MagicItemCatalog}
    {...props}
  />
)

export default MagicItemPageTemplate
