import arrify from 'arrify'
import React from 'react'

import {magicItemRarityCollection} from '@/constants/magicItemRarityList'

import MagicItemType from './../MagicItemType'
import MagicItemTypeAdditionalText from './../MagicItemTypeAdditionalText'

import './MagicItemParamsStyles.less'

const MagicItemParamsContainer = ({ magicItem, ...rest }) => {
  if (magicItem) {
    const {
      attunement,
      attunementComment,
      gearCategory,
      gearType,
      gearTypeExclude,
      genderId,
      rarity,
      type,
      typeComment,
    } = magicItem

    const rarityText = arrify(rarity).reduce(
      (result, rarityItem) => {
        let rarityId = rarityItem
        let comment = ''

        if (typeof rarityItem === 'object') {
          rarityId = rarityItem.value
          comment = rarityItem.comment
            ? ` ${rarityItem.comment}`
            : ''
        }

        const text = magicItemRarityCollection[rarityId].nameByGender[genderId]
        const currentText = `${text}${comment}`

        return result
          ? `${result}, ${currentText}`
          : currentText
      },
      ''
    )

    const attunementCommentText = attunementComment
      ? ` ${attunementComment}`
      : ``
    const attunementText = attunement
      ? `Требуется настройка${attunementCommentText}`
      : ``

    return (
      <section
        className='MagicItemParams'
        {...rest}
      >
        <div>
          <MagicItemType type={type}/>
          <MagicItemTypeAdditionalText
            gearCategory={gearCategory}
            gearType={gearType}
            gearTypeExclude={gearTypeExclude}
            typeComment={typeComment}
          />
          {`, ${rarityText}`}
        </div>
        {
          attunementText
            ? <div>{attunementText}</div>
            : null
        }
      </section>
    )
  }

  return null
}

export default MagicItemParamsContainer
