import React from 'react'

import SourceInfo from '@/components/SourceInfo'
import HeaderBlock from '@/components/HeaderBlock'
import Markdown from '@/components/Markdown'
import DescriptionList from '@/components/DescriptionList'

import generateTextLinks from '@/utils/generateTextLinks'

import MagicItemChildList from './components/MagicItemChildList'
import MagicItemImageList from './components/MagicItemImageList'
import MagicItemParams from './components/MagicItemParams'
import MagicItemSiblingList from './components/MagicItemSiblingList'

import './MagicItemStyles.less'

const MagicItemComponent = (
  {
    header,
    subHeader,
    descriptionAdditional,
    magicItem,
    text,
  },
) => (
  <section className='MagicItem'>
    <section className='MagicItem_infoBlock'>
      <HeaderBlock
        className='MagicItem_header'
        subHeader={subHeader}
      >
        {header}
      </HeaderBlock>
      <MagicItemParams
        className='MagicItem_params'
        magicItem={magicItem}
      />
      <Markdown className='MagicItem_description'>
        {generateTextLinks(text)}
      </Markdown>
      <SourceInfo
        className='MagicItem_source'
        source={magicItem.source}
        useFullName
      />
    </section>
    <MagicItemSiblingList
      idList={magicItem.siblingIdList}
      currentId={magicItem.id}
    />
    <MagicItemChildList idList={magicItem.childIdList}/>
    <MagicItemImageList id={magicItem.id}/>
    <DescriptionList description={descriptionAdditional}/>
  </section>
)

export default MagicItemComponent
