const listToCollectionById = require('./../../../utils/listToCollectionById')
const {
  SOURCE_MOOT,
} = require('./../../sourceList')
const {
  MAGIC_ITEM_AKMON_HAMMER_OF_PURPHOROS,
  MAGIC_ITEM_DEKELLA_BIDENT_OF_THASSA,
  MAGIC_ITEM_EPHIXIS_BOW_OF_NYLEA,
  MAGIC_ITEM_FLYING_CHARIOT,
  MAGIC_ITEM_PYXIS_OF_PANDEMONIUM,
} = require('./../../magicItemIdList')

const Akmon_hammer_of_purphoros_MOOT_img = require('./../../../images/magicItems/moot/akmon_hammer_of_purphoros.jpeg')
const Flying_chariot_MOOT_img = require('./../../../images/magicItems/moot/flying_chariot.jpg')
const Pyxis_of_pandemonium_MOOT_img = require('./../../../images/magicItems/moot/pyxis_of_pandemonium.jpg')
const Dekella_bident_of_thassa_MOOT_img = require('./../../../images/magicItems/moot/dekella_bident_of_thassa.jpg')
const Ephixis_bow_of_nylea_MOOT_img = require('./../../../images/magicItems/moot/ephixis_bow_of_nylea.jpeg')

const magicItemsImageList_MOOT = [
  {
    id: MAGIC_ITEM_AKMON_HAMMER_OF_PURPHOROS,
    src: Akmon_hammer_of_purphoros_MOOT_img,
  },
  {
    id: MAGIC_ITEM_DEKELLA_BIDENT_OF_THASSA,
    src: Dekella_bident_of_thassa_MOOT_img,
  },
  {
    id: MAGIC_ITEM_EPHIXIS_BOW_OF_NYLEA,
    src: Ephixis_bow_of_nylea_MOOT_img,
  },
  {
    id: MAGIC_ITEM_FLYING_CHARIOT,
    src: Flying_chariot_MOOT_img,
  },
  {
    id: MAGIC_ITEM_PYXIS_OF_PANDEMONIUM,
    src: Pyxis_of_pandemonium_MOOT_img,
  },
]
  .map(
    gear => ({
      ...gear,
      source: {
        id: SOURCE_MOOT,
      },
    })
  )

module.exports = listToCollectionById(magicItemsImageList_MOOT)
