import React from 'react'
import { Link } from 'gatsby'

import './MagicItemListStyles.less'

const MagicItemListComponent = ({list, headerText}) => (
  <section className='MagicItemList'>
    <header className='MagicItemList_header'>
      {headerText}
    </header>
    <ol className='MagicItemList_list'>
      {
        list.map(
          ({id, name, url}) => (
            <li className='MagicItemList_item'>
              {
                url
                  ? (
                    <Link
                      className='MagicItemList_text MagicItemList_text-link'
                      to={url}
                      key={id}
                    >
                      {name}
                    </Link>
                  )
                  : (
                    <span
                      className='MagicItemList_text'
                      key={id}
                    >
                      {name}
                    </span>
                  )
              }
            </li>
          )
        )
      }
    </ol>
  </section>
)

export default MagicItemListComponent
