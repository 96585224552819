import React from "react"
import { Link } from 'gatsby'

import {gearCollection} from '@/constants/gearList'

import './GearLinkStyles.less'
import generateGearPageUrlById from '@/utils/generateGearPageUrlById'

export default ({gearId}) => {
  if (gearId) {
    const gear = gearCollection[gearId]

    if (gear) {
      const {name, description} = gear
      const title = description
        ? `${name}\n${description}`
        : name

      return (
        <Link
          className='GearLink'
          title={title}
          to={generateGearPageUrlById(gearId)}
        >
          {name}
        </Link>
      )
    }

    return gearId
  }

  return null
}
