import arrify from 'arrify'
import React from "react"

import {gearCategoryCollection} from '@/constants/gearCategoryList'

import './GearCategoryStyles.less'

export default ({catId}) => {
  if (catId) {
    const category = gearCategoryCollection[catId]

    if (category) {
      const {name, description} = category
      const descriptionMain = arrify(description)[0]
      const {text} = descriptionMain
        ? typeof descriptionMain === 'string'
          ? {
            text: descriptionMain,
          }
          : descriptionMain
        : {
          text: '',
        }

      return (
        <span
          className='GearCategory'
          title={`${name}\n\n${text}`}
        >
          {name}
        </span>
      )
    }

    return catId
  }

  return null
}
